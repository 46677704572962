import {createFileRoute} from '@tanstack/react-router';
import {applyStaffProtectedRoute} from '@routes/middlewares';

export const Route = createFileRoute('/ru/nedvizhimost/$propertyId/images/add')(
    {
        beforeLoad: async ({context}) => {
            await applyStaffProtectedRoute({user: context.user});
        },
    },
);
