import * as React from 'react';
import {createFileRoute, Outlet} from '@tanstack/react-router';

const LayoutComponent: React.FC = () => (
    <Outlet/>
);

export const Route = createFileRoute('/_layout')({
    component: LayoutComponent,
});
