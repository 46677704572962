/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../app/__root'
import { Route as LayoutImport } from './../app/_layout'
import { Route as PropertiesIndexImport } from './../app/properties/index'
import { Route as DevelopmentsIndexImport } from './../app/developments/index'
import { Route as PropertiesAddImport } from './../app/properties/add'
import { Route as DevelopmentsPropertiesImport } from './../app/developments/properties'
import { Route as RuNovostroykiIndexImport } from './../app/ru/novostroyki/index'
import { Route as RuNedvizhimostIndexImport } from './../app/ru/nedvizhimost/index'
import { Route as PropertiesRentIndexImport } from './../app/properties/rent/index'
import { Route as PropertiesBuyIndexImport } from './../app/properties/buy/index'
import { Route as AgentsExclusivesIndexImport } from './../app/agents/exclusives/index'
import { Route as AccountTasksIndexImport } from './../app/account/tasks/index'
import { Route as AccountLoginIndexImport } from './../app/account/login/index'
import { Route as AccountLeadsIndexImport } from './../app/account/leads/index'
import { Route as AccountDealsIndexImport } from './../app/account/deals/index'
import { Route as AccountContactsIndexImport } from './../app/account/contacts/index'
import { Route as VillagesCommunitySlugCommunityOfferImport } from './../app/villages/$communitySlug/$communityOffer'
import { Route as RuNovostroykiNedvizhimostImport } from './../app/ru/novostroyki/nedvizhimost'
import { Route as RuNedvizhimostRazmestitObyavlenieImport } from './../app/ru/nedvizhimost/razmestit-obyavlenie'
import { Route as PropertiesRentTownhouseImport } from './../app/properties/rent/townhouse'
import { Route as PropertiesRentHouseImport } from './../app/properties/rent/house'
import { Route as PropertiesRentFlatImport } from './../app/properties/rent/flat'
import { Route as PropertiesBuyTownhouseImport } from './../app/properties/buy/townhouse'
import { Route as PropertiesBuyLandImport } from './../app/properties/buy/land'
import { Route as PropertiesBuyHouseImport } from './../app/properties/buy/house'
import { Route as PropertiesBuyFlatImport } from './../app/properties/buy/flat'
import { Route as DevelopmentsDevelopmentSlugDevelopmentOfferImport } from './../app/developments/$developmentSlug/$developmentOffer'
import { Route as AccountDealsAddImport } from './../app/account/deals/add'
import { Route as RuNedvizhimostSnyatIndexImport } from './../app/ru/nedvizhimost/snyat/index'
import { Route as RuNedvizhimostKupitIndexImport } from './../app/ru/nedvizhimost/kupit/index'
import { Route as RuAgentiEkskluziviIndexImport } from './../app/ru/agenti/ekskluzivi/index'
import { Route as RuAccountTasksIndexImport } from './../app/ru/account/tasks/index'
import { Route as RuAccountLoginIndexImport } from './../app/ru/account/login/index'
import { Route as RuAccountLeadsIndexImport } from './../app/ru/account/leads/index'
import { Route as RuAccountDealsIndexImport } from './../app/ru/account/deals/index'
import { Route as RuAccountContactsIndexImport } from './../app/ru/account/contacts/index'
import { Route as PropertiesPropertyIdImagesIndexImport } from './../app/properties/$propertyId/images/index'
import { Route as AccountWhatsappAccountsIndexImport } from './../app/account/whatsapp/accounts/index'
import { Route as AccountTasksTaskIdIndexImport } from './../app/account/tasks/$taskId/index'
import { Route as AccountLeadsLeadIdIndexImport } from './../app/account/leads/$leadId/index'
import { Route as AccountDealsDealIdIndexImport } from './../app/account/deals/$dealId/index'
import { Route as AccountContactsContactIdIndexImport } from './../app/account/contacts/$contactId/index'
import { Route as RuNovostroykiDevelopmentSlugDevelopmentOfferImport } from './../app/ru/novostroyki/$developmentSlug/$developmentOffer'
import { Route as RuNedvizhimostSnyatTaunhausImport } from './../app/ru/nedvizhimost/snyat/taunhaus'
import { Route as RuNedvizhimostSnyatKvartiraImport } from './../app/ru/nedvizhimost/snyat/kvartira'
import { Route as RuNedvizhimostSnyatDomImport } from './../app/ru/nedvizhimost/snyat/dom'
import { Route as RuNedvizhimostKupitUchastokImport } from './../app/ru/nedvizhimost/kupit/uchastok'
import { Route as RuNedvizhimostKupitTaunhausImport } from './../app/ru/nedvizhimost/kupit/taunhaus'
import { Route as RuNedvizhimostKupitKvartiraImport } from './../app/ru/nedvizhimost/kupit/kvartira'
import { Route as RuNedvizhimostKupitDomImport } from './../app/ru/nedvizhimost/kupit/dom'
import { Route as RuKottedzhnyePoselkiCommunitySlugCommunityOfferImport } from './../app/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
import { Route as RuAccountDealsAddImport } from './../app/ru/account/deals/add'
import { Route as PropertiesPropertyIdImagesAddImport } from './../app/properties/$propertyId/images/add'
import { Route as RuNedvizhimostPropertyIdImagesIndexImport } from './../app/ru/nedvizhimost/$propertyId/images/index'
import { Route as RuAccountWhatsappAccountsIndexImport } from './../app/ru/account/whatsapp/accounts/index'
import { Route as RuAccountTasksTaskIdIndexImport } from './../app/ru/account/tasks/$taskId/index'
import { Route as RuAccountLeadsLeadIdIndexImport } from './../app/ru/account/leads/$leadId/index'
import { Route as RuAccountDealsDealIdIndexImport } from './../app/ru/account/deals/$dealId/index'
import { Route as RuAccountContactsContactIdIndexImport } from './../app/ru/account/contacts/$contactId/index'
import { Route as AccountWhatsappAccountsWhatsappAccountIdIndexImport } from './../app/account/whatsapp/accounts/$whatsappAccountId/index'
import { Route as RuNedvizhimostPropertyIdImagesAddImport } from './../app/ru/nedvizhimost/$propertyId/images/add'
import { Route as RuAccountWhatsappAccountsWhatsappAccountIdIndexImport } from './../app/ru/account/whatsapp/accounts/$whatsappAccountId/index'

// Create Virtual Routes

const SelectionLazyImport = createFileRoute('/selection')()
const PrivacyLazyImport = createFileRoute('/privacy')()
const InvestmentsLazyImport = createFileRoute('/investments')()
const SplatLazyImport = createFileRoute('/$')()
const IndexLazyImport = createFileRoute('/')()
const VillagesIndexLazyImport = createFileRoute('/villages/')()
const RuIndexLazyImport = createFileRoute('/ru/')()
const RuPrivacyLazyImport = createFileRoute('/ru/privacy')()
const RuPodborLazyImport = createFileRoute('/ru/podbor')()
const RuInvesticiiLazyImport = createFileRoute('/ru/investicii')()
const AgentsAgentUsernameLazyImport = createFileRoute(
  '/agents/$agentUsername',
)()
const VillagesCommunitySlugIndexLazyImport = createFileRoute(
  '/villages/$communitySlug/',
)()
const RuKottedzhnyePoselkiIndexLazyImport = createFileRoute(
  '/ru/kottedzhnye-poselki/',
)()
const PropertiesPropertyIdIndexLazyImport = createFileRoute(
  '/properties/$propertyId/',
)()
const DevelopmentsDevelopmentSlugIndexLazyImport = createFileRoute(
  '/developments/$developmentSlug/',
)()
const RuAgentiAgentUsernameLazyImport = createFileRoute(
  '/ru/agenti/$agentUsername',
)()
const RuNovostroykiDevelopmentSlugIndexLazyImport = createFileRoute(
  '/ru/novostroyki/$developmentSlug/',
)()
const RuNedvizhimostPropertyIdIndexLazyImport = createFileRoute(
  '/ru/nedvizhimost/$propertyId/',
)()
const RuKottedzhnyePoselkiCommunitySlugIndexLazyImport = createFileRoute(
  '/ru/kottedzhnye-poselki/$communitySlug/',
)()
const EmailsUnsubscribeKeyIndexLazyImport = createFileRoute(
  '/emails/unsubscribe/$key/',
)()
const EmailsSubscribeKeyIndexLazyImport = createFileRoute(
  '/emails/subscribe/$key/',
)()
const AgentsExclusivesPropertyIdIndexLazyImport = createFileRoute(
  '/agents/exclusives/$propertyId/',
)()
const EmailsUnsubscribeKeyConfirmedLazyImport = createFileRoute(
  '/emails/unsubscribe/$key/confirmed',
)()
const EmailsSubscribeKeyConfirmedLazyImport = createFileRoute(
  '/emails/subscribe/$key/confirmed',
)()
const RuEmailsUnsubscribeKeyIndexLazyImport = createFileRoute(
  '/ru/emails/unsubscribe/$key/',
)()
const RuEmailsSubscribeKeyIndexLazyImport = createFileRoute(
  '/ru/emails/subscribe/$key/',
)()
const RuAgentiEkskluziviPropertyIdIndexLazyImport = createFileRoute(
  '/ru/agenti/ekskluzivi/$propertyId/',
)()
const RuEmailsUnsubscribeKeyConfirmedLazyImport = createFileRoute(
  '/ru/emails/unsubscribe/$key/confirmed',
)()
const RuEmailsSubscribeKeyConfirmedLazyImport = createFileRoute(
  '/ru/emails/subscribe/$key/confirmed',
)()

// Create/Update Routes

const SelectionLazyRoute = SelectionLazyImport.update({
  id: '/selection',
  path: '/selection',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/selection.lazy').then((d) => d.Route))

const PrivacyLazyRoute = PrivacyLazyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/privacy.lazy').then((d) => d.Route))

const InvestmentsLazyRoute = InvestmentsLazyImport.update({
  id: '/investments',
  path: '/investments',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/investments.lazy').then((d) => d.Route))

const SplatLazyRoute = SplatLazyImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/$.lazy').then((d) => d.Route))

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/index.lazy').then((d) => d.Route))

const VillagesIndexLazyRoute = VillagesIndexLazyImport.update({
  id: '/villages/',
  path: '/villages/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/villages/index.lazy').then((d) => d.Route),
)

const RuIndexLazyRoute = RuIndexLazyImport.update({
  id: '/ru/',
  path: '/ru/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/ru/index.lazy').then((d) => d.Route))

const PropertiesIndexRoute = PropertiesIndexImport.update({
  id: '/properties/',
  path: '/properties/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/index.lazy').then((d) => d.Route),
)

const DevelopmentsIndexRoute = DevelopmentsIndexImport.update({
  id: '/developments/',
  path: '/developments/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/developments/index.lazy').then((d) => d.Route),
)

const RuPrivacyLazyRoute = RuPrivacyLazyImport.update({
  id: '/ru/privacy',
  path: '/ru/privacy',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/ru/privacy.lazy').then((d) => d.Route))

const RuPodborLazyRoute = RuPodborLazyImport.update({
  id: '/ru/podbor',
  path: '/ru/podbor',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/ru/podbor.lazy').then((d) => d.Route))

const RuInvesticiiLazyRoute = RuInvesticiiLazyImport.update({
  id: '/ru/investicii',
  path: '/ru/investicii',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../app/ru/investicii.lazy').then((d) => d.Route))

const AgentsAgentUsernameLazyRoute = AgentsAgentUsernameLazyImport.update({
  id: '/agents/$agentUsername',
  path: '/agents/$agentUsername',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/agents/$agentUsername.lazy').then((d) => d.Route),
)

const PropertiesAddRoute = PropertiesAddImport.update({
  id: '/properties/add',
  path: '/properties/add',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/add.lazy').then((d) => d.Route),
)

const DevelopmentsPropertiesRoute = DevelopmentsPropertiesImport.update({
  id: '/developments/properties',
  path: '/developments/properties',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/developments/properties.lazy').then((d) => d.Route),
)

const VillagesCommunitySlugIndexLazyRoute =
  VillagesCommunitySlugIndexLazyImport.update({
    id: '/villages/$communitySlug/',
    path: '/villages/$communitySlug/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/villages/$communitySlug/index.lazy').then((d) => d.Route),
  )

const RuKottedzhnyePoselkiIndexLazyRoute =
  RuKottedzhnyePoselkiIndexLazyImport.update({
    id: '/ru/kottedzhnye-poselki/',
    path: '/ru/kottedzhnye-poselki/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/kottedzhnye-poselki/index.lazy').then((d) => d.Route),
  )

const PropertiesPropertyIdIndexLazyRoute =
  PropertiesPropertyIdIndexLazyImport.update({
    id: '/properties/$propertyId/',
    path: '/properties/$propertyId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/properties/$propertyId/index.lazy').then((d) => d.Route),
  )

const DevelopmentsDevelopmentSlugIndexLazyRoute =
  DevelopmentsDevelopmentSlugIndexLazyImport.update({
    id: '/developments/$developmentSlug/',
    path: '/developments/$developmentSlug/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/developments/$developmentSlug/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNovostroykiIndexRoute = RuNovostroykiIndexImport.update({
  id: '/ru/novostroyki/',
  path: '/ru/novostroyki/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/novostroyki/index.lazy').then((d) => d.Route),
)

const RuNedvizhimostIndexRoute = RuNedvizhimostIndexImport.update({
  id: '/ru/nedvizhimost/',
  path: '/ru/nedvizhimost/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/nedvizhimost/index.lazy').then((d) => d.Route),
)

const PropertiesRentIndexRoute = PropertiesRentIndexImport.update({
  id: '/properties/rent/',
  path: '/properties/rent/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/rent/index.lazy').then((d) => d.Route),
)

const PropertiesBuyIndexRoute = PropertiesBuyIndexImport.update({
  id: '/properties/buy/',
  path: '/properties/buy/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/buy/index.lazy').then((d) => d.Route),
)

const AgentsExclusivesIndexRoute = AgentsExclusivesIndexImport.update({
  id: '/agents/exclusives/',
  path: '/agents/exclusives/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/agents/exclusives/index.lazy').then((d) => d.Route),
)

const AccountTasksIndexRoute = AccountTasksIndexImport.update({
  id: '/account/tasks/',
  path: '/account/tasks/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/tasks/index.lazy').then((d) => d.Route),
)

const AccountLoginIndexRoute = AccountLoginIndexImport.update({
  id: '/account/login/',
  path: '/account/login/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/login/index.lazy').then((d) => d.Route),
)

const AccountLeadsIndexRoute = AccountLeadsIndexImport.update({
  id: '/account/leads/',
  path: '/account/leads/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/leads/index.lazy').then((d) => d.Route),
)

const AccountDealsIndexRoute = AccountDealsIndexImport.update({
  id: '/account/deals/',
  path: '/account/deals/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/deals/index.lazy').then((d) => d.Route),
)

const AccountContactsIndexRoute = AccountContactsIndexImport.update({
  id: '/account/contacts/',
  path: '/account/contacts/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/contacts/index.lazy').then((d) => d.Route),
)

const RuAgentiAgentUsernameLazyRoute = RuAgentiAgentUsernameLazyImport.update({
  id: '/ru/agenti/$agentUsername',
  path: '/ru/agenti/$agentUsername',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/agenti/$agentUsername.lazy').then((d) => d.Route),
)

const VillagesCommunitySlugCommunityOfferRoute =
  VillagesCommunitySlugCommunityOfferImport.update({
    id: '/villages/$communitySlug/$communityOffer',
    path: '/villages/$communitySlug/$communityOffer',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/villages/$communitySlug/$communityOffer.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNovostroykiNedvizhimostRoute = RuNovostroykiNedvizhimostImport.update({
  id: '/ru/novostroyki/nedvizhimost',
  path: '/ru/novostroyki/nedvizhimost',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/novostroyki/nedvizhimost.lazy').then((d) => d.Route),
)

const RuNedvizhimostRazmestitObyavlenieRoute =
  RuNedvizhimostRazmestitObyavlenieImport.update({
    id: '/ru/nedvizhimost/razmestit-obyavlenie',
    path: '/ru/nedvizhimost/razmestit-obyavlenie',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/razmestit-obyavlenie.lazy').then(
      (d) => d.Route,
    ),
  )

const PropertiesRentTownhouseRoute = PropertiesRentTownhouseImport.update({
  id: '/properties/rent/townhouse',
  path: '/properties/rent/townhouse',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/rent/townhouse.lazy').then((d) => d.Route),
)

const PropertiesRentHouseRoute = PropertiesRentHouseImport.update({
  id: '/properties/rent/house',
  path: '/properties/rent/house',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/rent/house.lazy').then((d) => d.Route),
)

const PropertiesRentFlatRoute = PropertiesRentFlatImport.update({
  id: '/properties/rent/flat',
  path: '/properties/rent/flat',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/rent/flat.lazy').then((d) => d.Route),
)

const PropertiesBuyTownhouseRoute = PropertiesBuyTownhouseImport.update({
  id: '/properties/buy/townhouse',
  path: '/properties/buy/townhouse',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/buy/townhouse.lazy').then((d) => d.Route),
)

const PropertiesBuyLandRoute = PropertiesBuyLandImport.update({
  id: '/properties/buy/land',
  path: '/properties/buy/land',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/buy/land.lazy').then((d) => d.Route),
)

const PropertiesBuyHouseRoute = PropertiesBuyHouseImport.update({
  id: '/properties/buy/house',
  path: '/properties/buy/house',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/buy/house.lazy').then((d) => d.Route),
)

const PropertiesBuyFlatRoute = PropertiesBuyFlatImport.update({
  id: '/properties/buy/flat',
  path: '/properties/buy/flat',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/properties/buy/flat.lazy').then((d) => d.Route),
)

const DevelopmentsDevelopmentSlugDevelopmentOfferRoute =
  DevelopmentsDevelopmentSlugDevelopmentOfferImport.update({
    id: '/developments/$developmentSlug/$developmentOffer',
    path: '/developments/$developmentSlug/$developmentOffer',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './../app/developments/$developmentSlug/$developmentOffer.lazy'
    ).then((d) => d.Route),
  )

const AccountDealsAddRoute = AccountDealsAddImport.update({
  id: '/account/deals/add',
  path: '/account/deals/add',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/deals/add.lazy').then((d) => d.Route),
)

const RuNovostroykiDevelopmentSlugIndexLazyRoute =
  RuNovostroykiDevelopmentSlugIndexLazyImport.update({
    id: '/ru/novostroyki/$developmentSlug/',
    path: '/ru/novostroyki/$developmentSlug/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/novostroyki/$developmentSlug/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNedvizhimostPropertyIdIndexLazyRoute =
  RuNedvizhimostPropertyIdIndexLazyImport.update({
    id: '/ru/nedvizhimost/$propertyId/',
    path: '/ru/nedvizhimost/$propertyId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/$propertyId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute =
  RuKottedzhnyePoselkiCommunitySlugIndexLazyImport.update({
    id: '/ru/kottedzhnye-poselki/$communitySlug/',
    path: '/ru/kottedzhnye-poselki/$communitySlug/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/kottedzhnye-poselki/$communitySlug/index.lazy').then(
      (d) => d.Route,
    ),
  )

const EmailsUnsubscribeKeyIndexLazyRoute =
  EmailsUnsubscribeKeyIndexLazyImport.update({
    id: '/emails/unsubscribe/$key/',
    path: '/emails/unsubscribe/$key/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/emails/unsubscribe/$key/index.lazy').then((d) => d.Route),
  )

const EmailsSubscribeKeyIndexLazyRoute =
  EmailsSubscribeKeyIndexLazyImport.update({
    id: '/emails/subscribe/$key/',
    path: '/emails/subscribe/$key/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/emails/subscribe/$key/index.lazy').then((d) => d.Route),
  )

const AgentsExclusivesPropertyIdIndexLazyRoute =
  AgentsExclusivesPropertyIdIndexLazyImport.update({
    id: '/agents/exclusives/$propertyId/',
    path: '/agents/exclusives/$propertyId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/agents/exclusives/$propertyId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNedvizhimostSnyatIndexRoute = RuNedvizhimostSnyatIndexImport.update({
  id: '/ru/nedvizhimost/snyat/',
  path: '/ru/nedvizhimost/snyat/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/nedvizhimost/snyat/index.lazy').then((d) => d.Route),
)

const RuNedvizhimostKupitIndexRoute = RuNedvizhimostKupitIndexImport.update({
  id: '/ru/nedvizhimost/kupit/',
  path: '/ru/nedvizhimost/kupit/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/nedvizhimost/kupit/index.lazy').then((d) => d.Route),
)

const RuAgentiEkskluziviIndexRoute = RuAgentiEkskluziviIndexImport.update({
  id: '/ru/agenti/ekskluzivi/',
  path: '/ru/agenti/ekskluzivi/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/agenti/ekskluzivi/index.lazy').then((d) => d.Route),
)

const RuAccountTasksIndexRoute = RuAccountTasksIndexImport.update({
  id: '/ru/account/tasks/',
  path: '/ru/account/tasks/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/tasks/index.lazy').then((d) => d.Route),
)

const RuAccountLoginIndexRoute = RuAccountLoginIndexImport.update({
  id: '/ru/account/login/',
  path: '/ru/account/login/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/login/index.lazy').then((d) => d.Route),
)

const RuAccountLeadsIndexRoute = RuAccountLeadsIndexImport.update({
  id: '/ru/account/leads/',
  path: '/ru/account/leads/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/leads/index.lazy').then((d) => d.Route),
)

const RuAccountDealsIndexRoute = RuAccountDealsIndexImport.update({
  id: '/ru/account/deals/',
  path: '/ru/account/deals/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/deals/index.lazy').then((d) => d.Route),
)

const RuAccountContactsIndexRoute = RuAccountContactsIndexImport.update({
  id: '/ru/account/contacts/',
  path: '/ru/account/contacts/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/contacts/index.lazy').then((d) => d.Route),
)

const PropertiesPropertyIdImagesIndexRoute =
  PropertiesPropertyIdImagesIndexImport.update({
    id: '/properties/$propertyId/images/',
    path: '/properties/$propertyId/images/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/properties/$propertyId/images/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountWhatsappAccountsIndexRoute =
  AccountWhatsappAccountsIndexImport.update({
    id: '/account/whatsapp/accounts/',
    path: '/account/whatsapp/accounts/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/account/whatsapp/accounts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountTasksTaskIdIndexRoute = AccountTasksTaskIdIndexImport.update({
  id: '/account/tasks/$taskId/',
  path: '/account/tasks/$taskId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/tasks/$taskId/index.lazy').then((d) => d.Route),
)

const AccountLeadsLeadIdIndexRoute = AccountLeadsLeadIdIndexImport.update({
  id: '/account/leads/$leadId/',
  path: '/account/leads/$leadId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/leads/$leadId/index.lazy').then((d) => d.Route),
)

const AccountDealsDealIdIndexRoute = AccountDealsDealIdIndexImport.update({
  id: '/account/deals/$dealId/',
  path: '/account/deals/$dealId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/account/deals/$dealId/index.lazy').then((d) => d.Route),
)

const AccountContactsContactIdIndexRoute =
  AccountContactsContactIdIndexImport.update({
    id: '/account/contacts/$contactId/',
    path: '/account/contacts/$contactId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/account/contacts/$contactId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const EmailsUnsubscribeKeyConfirmedLazyRoute =
  EmailsUnsubscribeKeyConfirmedLazyImport.update({
    id: '/emails/unsubscribe/$key/confirmed',
    path: '/emails/unsubscribe/$key/confirmed',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/emails/unsubscribe/$key/confirmed.lazy').then(
      (d) => d.Route,
    ),
  )

const EmailsSubscribeKeyConfirmedLazyRoute =
  EmailsSubscribeKeyConfirmedLazyImport.update({
    id: '/emails/subscribe/$key/confirmed',
    path: '/emails/subscribe/$key/confirmed',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/emails/subscribe/$key/confirmed.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNovostroykiDevelopmentSlugDevelopmentOfferRoute =
  RuNovostroykiDevelopmentSlugDevelopmentOfferImport.update({
    id: '/ru/novostroyki/$developmentSlug/$developmentOffer',
    path: '/ru/novostroyki/$developmentSlug/$developmentOffer',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './../app/ru/novostroyki/$developmentSlug/$developmentOffer.lazy'
    ).then((d) => d.Route),
  )

const RuNedvizhimostSnyatTaunhausRoute =
  RuNedvizhimostSnyatTaunhausImport.update({
    id: '/ru/nedvizhimost/snyat/taunhaus',
    path: '/ru/nedvizhimost/snyat/taunhaus',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/snyat/taunhaus.lazy').then((d) => d.Route),
  )

const RuNedvizhimostSnyatKvartiraRoute =
  RuNedvizhimostSnyatKvartiraImport.update({
    id: '/ru/nedvizhimost/snyat/kvartira',
    path: '/ru/nedvizhimost/snyat/kvartira',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/snyat/kvartira.lazy').then((d) => d.Route),
  )

const RuNedvizhimostSnyatDomRoute = RuNedvizhimostSnyatDomImport.update({
  id: '/ru/nedvizhimost/snyat/dom',
  path: '/ru/nedvizhimost/snyat/dom',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/nedvizhimost/snyat/dom.lazy').then((d) => d.Route),
)

const RuNedvizhimostKupitUchastokRoute =
  RuNedvizhimostKupitUchastokImport.update({
    id: '/ru/nedvizhimost/kupit/uchastok',
    path: '/ru/nedvizhimost/kupit/uchastok',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/kupit/uchastok.lazy').then((d) => d.Route),
  )

const RuNedvizhimostKupitTaunhausRoute =
  RuNedvizhimostKupitTaunhausImport.update({
    id: '/ru/nedvizhimost/kupit/taunhaus',
    path: '/ru/nedvizhimost/kupit/taunhaus',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/kupit/taunhaus.lazy').then((d) => d.Route),
  )

const RuNedvizhimostKupitKvartiraRoute =
  RuNedvizhimostKupitKvartiraImport.update({
    id: '/ru/nedvizhimost/kupit/kvartira',
    path: '/ru/nedvizhimost/kupit/kvartira',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/kupit/kvartira.lazy').then((d) => d.Route),
  )

const RuNedvizhimostKupitDomRoute = RuNedvizhimostKupitDomImport.update({
  id: '/ru/nedvizhimost/kupit/dom',
  path: '/ru/nedvizhimost/kupit/dom',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/nedvizhimost/kupit/dom.lazy').then((d) => d.Route),
)

const RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute =
  RuKottedzhnyePoselkiCommunitySlugCommunityOfferImport.update({
    id: '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer',
    path: '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './../app/ru/kottedzhnye-poselki/$communitySlug/$communityOffer.lazy'
    ).then((d) => d.Route),
  )

const RuAccountDealsAddRoute = RuAccountDealsAddImport.update({
  id: '/ru/account/deals/add',
  path: '/ru/account/deals/add',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/deals/add.lazy').then((d) => d.Route),
)

const PropertiesPropertyIdImagesAddRoute =
  PropertiesPropertyIdImagesAddImport.update({
    id: '/properties/$propertyId/images/add',
    path: '/properties/$propertyId/images/add',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/properties/$propertyId/images/add.lazy').then(
      (d) => d.Route,
    ),
  )

const RuEmailsUnsubscribeKeyIndexLazyRoute =
  RuEmailsUnsubscribeKeyIndexLazyImport.update({
    id: '/ru/emails/unsubscribe/$key/',
    path: '/ru/emails/unsubscribe/$key/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/emails/unsubscribe/$key/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuEmailsSubscribeKeyIndexLazyRoute =
  RuEmailsSubscribeKeyIndexLazyImport.update({
    id: '/ru/emails/subscribe/$key/',
    path: '/ru/emails/subscribe/$key/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/emails/subscribe/$key/index.lazy').then((d) => d.Route),
  )

const RuAgentiEkskluziviPropertyIdIndexLazyRoute =
  RuAgentiEkskluziviPropertyIdIndexLazyImport.update({
    id: '/ru/agenti/ekskluzivi/$propertyId/',
    path: '/ru/agenti/ekskluzivi/$propertyId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/agenti/ekskluzivi/$propertyId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNedvizhimostPropertyIdImagesIndexRoute =
  RuNedvizhimostPropertyIdImagesIndexImport.update({
    id: '/ru/nedvizhimost/$propertyId/images/',
    path: '/ru/nedvizhimost/$propertyId/images/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/$propertyId/images/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuAccountWhatsappAccountsIndexRoute =
  RuAccountWhatsappAccountsIndexImport.update({
    id: '/ru/account/whatsapp/accounts/',
    path: '/ru/account/whatsapp/accounts/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/account/whatsapp/accounts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RuAccountTasksTaskIdIndexRoute = RuAccountTasksTaskIdIndexImport.update({
  id: '/ru/account/tasks/$taskId/',
  path: '/ru/account/tasks/$taskId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/tasks/$taskId/index.lazy').then((d) => d.Route),
)

const RuAccountLeadsLeadIdIndexRoute = RuAccountLeadsLeadIdIndexImport.update({
  id: '/ru/account/leads/$leadId/',
  path: '/ru/account/leads/$leadId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/leads/$leadId/index.lazy').then((d) => d.Route),
)

const RuAccountDealsDealIdIndexRoute = RuAccountDealsDealIdIndexImport.update({
  id: '/ru/account/deals/$dealId/',
  path: '/ru/account/deals/$dealId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../app/ru/account/deals/$dealId/index.lazy').then((d) => d.Route),
)

const RuAccountContactsContactIdIndexRoute =
  RuAccountContactsContactIdIndexImport.update({
    id: '/ru/account/contacts/$contactId/',
    path: '/ru/account/contacts/$contactId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/account/contacts/$contactId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountWhatsappAccountsWhatsappAccountIdIndexRoute =
  AccountWhatsappAccountsWhatsappAccountIdIndexImport.update({
    id: '/account/whatsapp/accounts/$whatsappAccountId/',
    path: '/account/whatsapp/accounts/$whatsappAccountId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './../app/account/whatsapp/accounts/$whatsappAccountId/index.lazy'
    ).then((d) => d.Route),
  )

const RuEmailsUnsubscribeKeyConfirmedLazyRoute =
  RuEmailsUnsubscribeKeyConfirmedLazyImport.update({
    id: '/ru/emails/unsubscribe/$key/confirmed',
    path: '/ru/emails/unsubscribe/$key/confirmed',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/emails/unsubscribe/$key/confirmed.lazy').then(
      (d) => d.Route,
    ),
  )

const RuEmailsSubscribeKeyConfirmedLazyRoute =
  RuEmailsSubscribeKeyConfirmedLazyImport.update({
    id: '/ru/emails/subscribe/$key/confirmed',
    path: '/ru/emails/subscribe/$key/confirmed',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/emails/subscribe/$key/confirmed.lazy').then(
      (d) => d.Route,
    ),
  )

const RuNedvizhimostPropertyIdImagesAddRoute =
  RuNedvizhimostPropertyIdImagesAddImport.update({
    id: '/ru/nedvizhimost/$propertyId/images/add',
    path: '/ru/nedvizhimost/$propertyId/images/add',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./../app/ru/nedvizhimost/$propertyId/images/add.lazy').then(
      (d) => d.Route,
    ),
  )

const RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute =
  RuAccountWhatsappAccountsWhatsappAccountIdIndexImport.update({
    id: '/ru/account/whatsapp/accounts/$whatsappAccountId/',
    path: '/ru/account/whatsapp/accounts/$whatsappAccountId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './../app/ru/account/whatsapp/accounts/$whatsappAccountId/index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/$': {
      id: '/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof SplatLazyImport
      parentRoute: typeof rootRoute
    }
    '/investments': {
      id: '/investments'
      path: '/investments'
      fullPath: '/investments'
      preLoaderRoute: typeof InvestmentsLazyImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyLazyImport
      parentRoute: typeof rootRoute
    }
    '/selection': {
      id: '/selection'
      path: '/selection'
      fullPath: '/selection'
      preLoaderRoute: typeof SelectionLazyImport
      parentRoute: typeof rootRoute
    }
    '/developments/properties': {
      id: '/developments/properties'
      path: '/developments/properties'
      fullPath: '/developments/properties'
      preLoaderRoute: typeof DevelopmentsPropertiesImport
      parentRoute: typeof rootRoute
    }
    '/properties/add': {
      id: '/properties/add'
      path: '/properties/add'
      fullPath: '/properties/add'
      preLoaderRoute: typeof PropertiesAddImport
      parentRoute: typeof rootRoute
    }
    '/agents/$agentUsername': {
      id: '/agents/$agentUsername'
      path: '/agents/$agentUsername'
      fullPath: '/agents/$agentUsername'
      preLoaderRoute: typeof AgentsAgentUsernameLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/investicii': {
      id: '/ru/investicii'
      path: '/ru/investicii'
      fullPath: '/ru/investicii'
      preLoaderRoute: typeof RuInvesticiiLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/podbor': {
      id: '/ru/podbor'
      path: '/ru/podbor'
      fullPath: '/ru/podbor'
      preLoaderRoute: typeof RuPodborLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/privacy': {
      id: '/ru/privacy'
      path: '/ru/privacy'
      fullPath: '/ru/privacy'
      preLoaderRoute: typeof RuPrivacyLazyImport
      parentRoute: typeof rootRoute
    }
    '/developments/': {
      id: '/developments/'
      path: '/developments'
      fullPath: '/developments'
      preLoaderRoute: typeof DevelopmentsIndexImport
      parentRoute: typeof rootRoute
    }
    '/properties/': {
      id: '/properties/'
      path: '/properties'
      fullPath: '/properties'
      preLoaderRoute: typeof PropertiesIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/': {
      id: '/ru/'
      path: '/ru'
      fullPath: '/ru'
      preLoaderRoute: typeof RuIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/villages/': {
      id: '/villages/'
      path: '/villages'
      fullPath: '/villages'
      preLoaderRoute: typeof VillagesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/account/deals/add': {
      id: '/account/deals/add'
      path: '/account/deals/add'
      fullPath: '/account/deals/add'
      preLoaderRoute: typeof AccountDealsAddImport
      parentRoute: typeof rootRoute
    }
    '/developments/$developmentSlug/$developmentOffer': {
      id: '/developments/$developmentSlug/$developmentOffer'
      path: '/developments/$developmentSlug/$developmentOffer'
      fullPath: '/developments/$developmentSlug/$developmentOffer'
      preLoaderRoute: typeof DevelopmentsDevelopmentSlugDevelopmentOfferImport
      parentRoute: typeof rootRoute
    }
    '/properties/buy/flat': {
      id: '/properties/buy/flat'
      path: '/properties/buy/flat'
      fullPath: '/properties/buy/flat'
      preLoaderRoute: typeof PropertiesBuyFlatImport
      parentRoute: typeof rootRoute
    }
    '/properties/buy/house': {
      id: '/properties/buy/house'
      path: '/properties/buy/house'
      fullPath: '/properties/buy/house'
      preLoaderRoute: typeof PropertiesBuyHouseImport
      parentRoute: typeof rootRoute
    }
    '/properties/buy/land': {
      id: '/properties/buy/land'
      path: '/properties/buy/land'
      fullPath: '/properties/buy/land'
      preLoaderRoute: typeof PropertiesBuyLandImport
      parentRoute: typeof rootRoute
    }
    '/properties/buy/townhouse': {
      id: '/properties/buy/townhouse'
      path: '/properties/buy/townhouse'
      fullPath: '/properties/buy/townhouse'
      preLoaderRoute: typeof PropertiesBuyTownhouseImport
      parentRoute: typeof rootRoute
    }
    '/properties/rent/flat': {
      id: '/properties/rent/flat'
      path: '/properties/rent/flat'
      fullPath: '/properties/rent/flat'
      preLoaderRoute: typeof PropertiesRentFlatImport
      parentRoute: typeof rootRoute
    }
    '/properties/rent/house': {
      id: '/properties/rent/house'
      path: '/properties/rent/house'
      fullPath: '/properties/rent/house'
      preLoaderRoute: typeof PropertiesRentHouseImport
      parentRoute: typeof rootRoute
    }
    '/properties/rent/townhouse': {
      id: '/properties/rent/townhouse'
      path: '/properties/rent/townhouse'
      fullPath: '/properties/rent/townhouse'
      preLoaderRoute: typeof PropertiesRentTownhouseImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/razmestit-obyavlenie': {
      id: '/ru/nedvizhimost/razmestit-obyavlenie'
      path: '/ru/nedvizhimost/razmestit-obyavlenie'
      fullPath: '/ru/nedvizhimost/razmestit-obyavlenie'
      preLoaderRoute: typeof RuNedvizhimostRazmestitObyavlenieImport
      parentRoute: typeof rootRoute
    }
    '/ru/novostroyki/nedvizhimost': {
      id: '/ru/novostroyki/nedvizhimost'
      path: '/ru/novostroyki/nedvizhimost'
      fullPath: '/ru/novostroyki/nedvizhimost'
      preLoaderRoute: typeof RuNovostroykiNedvizhimostImport
      parentRoute: typeof rootRoute
    }
    '/villages/$communitySlug/$communityOffer': {
      id: '/villages/$communitySlug/$communityOffer'
      path: '/villages/$communitySlug/$communityOffer'
      fullPath: '/villages/$communitySlug/$communityOffer'
      preLoaderRoute: typeof VillagesCommunitySlugCommunityOfferImport
      parentRoute: typeof rootRoute
    }
    '/ru/agenti/$agentUsername': {
      id: '/ru/agenti/$agentUsername'
      path: '/ru/agenti/$agentUsername'
      fullPath: '/ru/agenti/$agentUsername'
      preLoaderRoute: typeof RuAgentiAgentUsernameLazyImport
      parentRoute: typeof rootRoute
    }
    '/account/contacts/': {
      id: '/account/contacts/'
      path: '/account/contacts'
      fullPath: '/account/contacts'
      preLoaderRoute: typeof AccountContactsIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/deals/': {
      id: '/account/deals/'
      path: '/account/deals'
      fullPath: '/account/deals'
      preLoaderRoute: typeof AccountDealsIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/leads/': {
      id: '/account/leads/'
      path: '/account/leads'
      fullPath: '/account/leads'
      preLoaderRoute: typeof AccountLeadsIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/login/': {
      id: '/account/login/'
      path: '/account/login'
      fullPath: '/account/login'
      preLoaderRoute: typeof AccountLoginIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/tasks/': {
      id: '/account/tasks/'
      path: '/account/tasks'
      fullPath: '/account/tasks'
      preLoaderRoute: typeof AccountTasksIndexImport
      parentRoute: typeof rootRoute
    }
    '/agents/exclusives/': {
      id: '/agents/exclusives/'
      path: '/agents/exclusives'
      fullPath: '/agents/exclusives'
      preLoaderRoute: typeof AgentsExclusivesIndexImport
      parentRoute: typeof rootRoute
    }
    '/properties/buy/': {
      id: '/properties/buy/'
      path: '/properties/buy'
      fullPath: '/properties/buy'
      preLoaderRoute: typeof PropertiesBuyIndexImport
      parentRoute: typeof rootRoute
    }
    '/properties/rent/': {
      id: '/properties/rent/'
      path: '/properties/rent'
      fullPath: '/properties/rent'
      preLoaderRoute: typeof PropertiesRentIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/': {
      id: '/ru/nedvizhimost/'
      path: '/ru/nedvizhimost'
      fullPath: '/ru/nedvizhimost'
      preLoaderRoute: typeof RuNedvizhimostIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/novostroyki/': {
      id: '/ru/novostroyki/'
      path: '/ru/novostroyki'
      fullPath: '/ru/novostroyki'
      preLoaderRoute: typeof RuNovostroykiIndexImport
      parentRoute: typeof rootRoute
    }
    '/developments/$developmentSlug/': {
      id: '/developments/$developmentSlug/'
      path: '/developments/$developmentSlug'
      fullPath: '/developments/$developmentSlug'
      preLoaderRoute: typeof DevelopmentsDevelopmentSlugIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/properties/$propertyId/': {
      id: '/properties/$propertyId/'
      path: '/properties/$propertyId'
      fullPath: '/properties/$propertyId'
      preLoaderRoute: typeof PropertiesPropertyIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/kottedzhnye-poselki/': {
      id: '/ru/kottedzhnye-poselki/'
      path: '/ru/kottedzhnye-poselki'
      fullPath: '/ru/kottedzhnye-poselki'
      preLoaderRoute: typeof RuKottedzhnyePoselkiIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/villages/$communitySlug/': {
      id: '/villages/$communitySlug/'
      path: '/villages/$communitySlug'
      fullPath: '/villages/$communitySlug'
      preLoaderRoute: typeof VillagesCommunitySlugIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/properties/$propertyId/images/add': {
      id: '/properties/$propertyId/images/add'
      path: '/properties/$propertyId/images/add'
      fullPath: '/properties/$propertyId/images/add'
      preLoaderRoute: typeof PropertiesPropertyIdImagesAddImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/deals/add': {
      id: '/ru/account/deals/add'
      path: '/ru/account/deals/add'
      fullPath: '/ru/account/deals/add'
      preLoaderRoute: typeof RuAccountDealsAddImport
      parentRoute: typeof rootRoute
    }
    '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer': {
      id: '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
      path: '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
      fullPath: '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
      preLoaderRoute: typeof RuKottedzhnyePoselkiCommunitySlugCommunityOfferImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/kupit/dom': {
      id: '/ru/nedvizhimost/kupit/dom'
      path: '/ru/nedvizhimost/kupit/dom'
      fullPath: '/ru/nedvizhimost/kupit/dom'
      preLoaderRoute: typeof RuNedvizhimostKupitDomImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/kupit/kvartira': {
      id: '/ru/nedvizhimost/kupit/kvartira'
      path: '/ru/nedvizhimost/kupit/kvartira'
      fullPath: '/ru/nedvizhimost/kupit/kvartira'
      preLoaderRoute: typeof RuNedvizhimostKupitKvartiraImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/kupit/taunhaus': {
      id: '/ru/nedvizhimost/kupit/taunhaus'
      path: '/ru/nedvizhimost/kupit/taunhaus'
      fullPath: '/ru/nedvizhimost/kupit/taunhaus'
      preLoaderRoute: typeof RuNedvizhimostKupitTaunhausImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/kupit/uchastok': {
      id: '/ru/nedvizhimost/kupit/uchastok'
      path: '/ru/nedvizhimost/kupit/uchastok'
      fullPath: '/ru/nedvizhimost/kupit/uchastok'
      preLoaderRoute: typeof RuNedvizhimostKupitUchastokImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/snyat/dom': {
      id: '/ru/nedvizhimost/snyat/dom'
      path: '/ru/nedvizhimost/snyat/dom'
      fullPath: '/ru/nedvizhimost/snyat/dom'
      preLoaderRoute: typeof RuNedvizhimostSnyatDomImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/snyat/kvartira': {
      id: '/ru/nedvizhimost/snyat/kvartira'
      path: '/ru/nedvizhimost/snyat/kvartira'
      fullPath: '/ru/nedvizhimost/snyat/kvartira'
      preLoaderRoute: typeof RuNedvizhimostSnyatKvartiraImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/snyat/taunhaus': {
      id: '/ru/nedvizhimost/snyat/taunhaus'
      path: '/ru/nedvizhimost/snyat/taunhaus'
      fullPath: '/ru/nedvizhimost/snyat/taunhaus'
      preLoaderRoute: typeof RuNedvizhimostSnyatTaunhausImport
      parentRoute: typeof rootRoute
    }
    '/ru/novostroyki/$developmentSlug/$developmentOffer': {
      id: '/ru/novostroyki/$developmentSlug/$developmentOffer'
      path: '/ru/novostroyki/$developmentSlug/$developmentOffer'
      fullPath: '/ru/novostroyki/$developmentSlug/$developmentOffer'
      preLoaderRoute: typeof RuNovostroykiDevelopmentSlugDevelopmentOfferImport
      parentRoute: typeof rootRoute
    }
    '/emails/subscribe/$key/confirmed': {
      id: '/emails/subscribe/$key/confirmed'
      path: '/emails/subscribe/$key/confirmed'
      fullPath: '/emails/subscribe/$key/confirmed'
      preLoaderRoute: typeof EmailsSubscribeKeyConfirmedLazyImport
      parentRoute: typeof rootRoute
    }
    '/emails/unsubscribe/$key/confirmed': {
      id: '/emails/unsubscribe/$key/confirmed'
      path: '/emails/unsubscribe/$key/confirmed'
      fullPath: '/emails/unsubscribe/$key/confirmed'
      preLoaderRoute: typeof EmailsUnsubscribeKeyConfirmedLazyImport
      parentRoute: typeof rootRoute
    }
    '/account/contacts/$contactId/': {
      id: '/account/contacts/$contactId/'
      path: '/account/contacts/$contactId'
      fullPath: '/account/contacts/$contactId'
      preLoaderRoute: typeof AccountContactsContactIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/deals/$dealId/': {
      id: '/account/deals/$dealId/'
      path: '/account/deals/$dealId'
      fullPath: '/account/deals/$dealId'
      preLoaderRoute: typeof AccountDealsDealIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/leads/$leadId/': {
      id: '/account/leads/$leadId/'
      path: '/account/leads/$leadId'
      fullPath: '/account/leads/$leadId'
      preLoaderRoute: typeof AccountLeadsLeadIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/tasks/$taskId/': {
      id: '/account/tasks/$taskId/'
      path: '/account/tasks/$taskId'
      fullPath: '/account/tasks/$taskId'
      preLoaderRoute: typeof AccountTasksTaskIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/account/whatsapp/accounts/': {
      id: '/account/whatsapp/accounts/'
      path: '/account/whatsapp/accounts'
      fullPath: '/account/whatsapp/accounts'
      preLoaderRoute: typeof AccountWhatsappAccountsIndexImport
      parentRoute: typeof rootRoute
    }
    '/properties/$propertyId/images/': {
      id: '/properties/$propertyId/images/'
      path: '/properties/$propertyId/images'
      fullPath: '/properties/$propertyId/images'
      preLoaderRoute: typeof PropertiesPropertyIdImagesIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/contacts/': {
      id: '/ru/account/contacts/'
      path: '/ru/account/contacts'
      fullPath: '/ru/account/contacts'
      preLoaderRoute: typeof RuAccountContactsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/deals/': {
      id: '/ru/account/deals/'
      path: '/ru/account/deals'
      fullPath: '/ru/account/deals'
      preLoaderRoute: typeof RuAccountDealsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/leads/': {
      id: '/ru/account/leads/'
      path: '/ru/account/leads'
      fullPath: '/ru/account/leads'
      preLoaderRoute: typeof RuAccountLeadsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/login/': {
      id: '/ru/account/login/'
      path: '/ru/account/login'
      fullPath: '/ru/account/login'
      preLoaderRoute: typeof RuAccountLoginIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/tasks/': {
      id: '/ru/account/tasks/'
      path: '/ru/account/tasks'
      fullPath: '/ru/account/tasks'
      preLoaderRoute: typeof RuAccountTasksIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/agenti/ekskluzivi/': {
      id: '/ru/agenti/ekskluzivi/'
      path: '/ru/agenti/ekskluzivi'
      fullPath: '/ru/agenti/ekskluzivi'
      preLoaderRoute: typeof RuAgentiEkskluziviIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/kupit/': {
      id: '/ru/nedvizhimost/kupit/'
      path: '/ru/nedvizhimost/kupit'
      fullPath: '/ru/nedvizhimost/kupit'
      preLoaderRoute: typeof RuNedvizhimostKupitIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/snyat/': {
      id: '/ru/nedvizhimost/snyat/'
      path: '/ru/nedvizhimost/snyat'
      fullPath: '/ru/nedvizhimost/snyat'
      preLoaderRoute: typeof RuNedvizhimostSnyatIndexImport
      parentRoute: typeof rootRoute
    }
    '/agents/exclusives/$propertyId/': {
      id: '/agents/exclusives/$propertyId/'
      path: '/agents/exclusives/$propertyId'
      fullPath: '/agents/exclusives/$propertyId'
      preLoaderRoute: typeof AgentsExclusivesPropertyIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/emails/subscribe/$key/': {
      id: '/emails/subscribe/$key/'
      path: '/emails/subscribe/$key'
      fullPath: '/emails/subscribe/$key'
      preLoaderRoute: typeof EmailsSubscribeKeyIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/emails/unsubscribe/$key/': {
      id: '/emails/unsubscribe/$key/'
      path: '/emails/unsubscribe/$key'
      fullPath: '/emails/unsubscribe/$key'
      preLoaderRoute: typeof EmailsUnsubscribeKeyIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/kottedzhnye-poselki/$communitySlug/': {
      id: '/ru/kottedzhnye-poselki/$communitySlug/'
      path: '/ru/kottedzhnye-poselki/$communitySlug'
      fullPath: '/ru/kottedzhnye-poselki/$communitySlug'
      preLoaderRoute: typeof RuKottedzhnyePoselkiCommunitySlugIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/$propertyId/': {
      id: '/ru/nedvizhimost/$propertyId/'
      path: '/ru/nedvizhimost/$propertyId'
      fullPath: '/ru/nedvizhimost/$propertyId'
      preLoaderRoute: typeof RuNedvizhimostPropertyIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/novostroyki/$developmentSlug/': {
      id: '/ru/novostroyki/$developmentSlug/'
      path: '/ru/novostroyki/$developmentSlug'
      fullPath: '/ru/novostroyki/$developmentSlug'
      preLoaderRoute: typeof RuNovostroykiDevelopmentSlugIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/$propertyId/images/add': {
      id: '/ru/nedvizhimost/$propertyId/images/add'
      path: '/ru/nedvizhimost/$propertyId/images/add'
      fullPath: '/ru/nedvizhimost/$propertyId/images/add'
      preLoaderRoute: typeof RuNedvizhimostPropertyIdImagesAddImport
      parentRoute: typeof rootRoute
    }
    '/ru/emails/subscribe/$key/confirmed': {
      id: '/ru/emails/subscribe/$key/confirmed'
      path: '/ru/emails/subscribe/$key/confirmed'
      fullPath: '/ru/emails/subscribe/$key/confirmed'
      preLoaderRoute: typeof RuEmailsSubscribeKeyConfirmedLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/emails/unsubscribe/$key/confirmed': {
      id: '/ru/emails/unsubscribe/$key/confirmed'
      path: '/ru/emails/unsubscribe/$key/confirmed'
      fullPath: '/ru/emails/unsubscribe/$key/confirmed'
      preLoaderRoute: typeof RuEmailsUnsubscribeKeyConfirmedLazyImport
      parentRoute: typeof rootRoute
    }
    '/account/whatsapp/accounts/$whatsappAccountId/': {
      id: '/account/whatsapp/accounts/$whatsappAccountId/'
      path: '/account/whatsapp/accounts/$whatsappAccountId'
      fullPath: '/account/whatsapp/accounts/$whatsappAccountId'
      preLoaderRoute: typeof AccountWhatsappAccountsWhatsappAccountIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/contacts/$contactId/': {
      id: '/ru/account/contacts/$contactId/'
      path: '/ru/account/contacts/$contactId'
      fullPath: '/ru/account/contacts/$contactId'
      preLoaderRoute: typeof RuAccountContactsContactIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/deals/$dealId/': {
      id: '/ru/account/deals/$dealId/'
      path: '/ru/account/deals/$dealId'
      fullPath: '/ru/account/deals/$dealId'
      preLoaderRoute: typeof RuAccountDealsDealIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/leads/$leadId/': {
      id: '/ru/account/leads/$leadId/'
      path: '/ru/account/leads/$leadId'
      fullPath: '/ru/account/leads/$leadId'
      preLoaderRoute: typeof RuAccountLeadsLeadIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/tasks/$taskId/': {
      id: '/ru/account/tasks/$taskId/'
      path: '/ru/account/tasks/$taskId'
      fullPath: '/ru/account/tasks/$taskId'
      preLoaderRoute: typeof RuAccountTasksTaskIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/whatsapp/accounts/': {
      id: '/ru/account/whatsapp/accounts/'
      path: '/ru/account/whatsapp/accounts'
      fullPath: '/ru/account/whatsapp/accounts'
      preLoaderRoute: typeof RuAccountWhatsappAccountsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/nedvizhimost/$propertyId/images/': {
      id: '/ru/nedvizhimost/$propertyId/images/'
      path: '/ru/nedvizhimost/$propertyId/images'
      fullPath: '/ru/nedvizhimost/$propertyId/images'
      preLoaderRoute: typeof RuNedvizhimostPropertyIdImagesIndexImport
      parentRoute: typeof rootRoute
    }
    '/ru/agenti/ekskluzivi/$propertyId/': {
      id: '/ru/agenti/ekskluzivi/$propertyId/'
      path: '/ru/agenti/ekskluzivi/$propertyId'
      fullPath: '/ru/agenti/ekskluzivi/$propertyId'
      preLoaderRoute: typeof RuAgentiEkskluziviPropertyIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/emails/subscribe/$key/': {
      id: '/ru/emails/subscribe/$key/'
      path: '/ru/emails/subscribe/$key'
      fullPath: '/ru/emails/subscribe/$key'
      preLoaderRoute: typeof RuEmailsSubscribeKeyIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/emails/unsubscribe/$key/': {
      id: '/ru/emails/unsubscribe/$key/'
      path: '/ru/emails/unsubscribe/$key'
      fullPath: '/ru/emails/unsubscribe/$key'
      preLoaderRoute: typeof RuEmailsUnsubscribeKeyIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/ru/account/whatsapp/accounts/$whatsappAccountId/': {
      id: '/ru/account/whatsapp/accounts/$whatsappAccountId/'
      path: '/ru/account/whatsapp/accounts/$whatsappAccountId'
      fullPath: '/ru/account/whatsapp/accounts/$whatsappAccountId'
      preLoaderRoute: typeof RuAccountWhatsappAccountsWhatsappAccountIdIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '': typeof LayoutRoute
  '/$': typeof SplatLazyRoute
  '/investments': typeof InvestmentsLazyRoute
  '/privacy': typeof PrivacyLazyRoute
  '/selection': typeof SelectionLazyRoute
  '/developments/properties': typeof DevelopmentsPropertiesRoute
  '/properties/add': typeof PropertiesAddRoute
  '/agents/$agentUsername': typeof AgentsAgentUsernameLazyRoute
  '/ru/investicii': typeof RuInvesticiiLazyRoute
  '/ru/podbor': typeof RuPodborLazyRoute
  '/ru/privacy': typeof RuPrivacyLazyRoute
  '/developments': typeof DevelopmentsIndexRoute
  '/properties': typeof PropertiesIndexRoute
  '/ru': typeof RuIndexLazyRoute
  '/villages': typeof VillagesIndexLazyRoute
  '/account/deals/add': typeof AccountDealsAddRoute
  '/developments/$developmentSlug/$developmentOffer': typeof DevelopmentsDevelopmentSlugDevelopmentOfferRoute
  '/properties/buy/flat': typeof PropertiesBuyFlatRoute
  '/properties/buy/house': typeof PropertiesBuyHouseRoute
  '/properties/buy/land': typeof PropertiesBuyLandRoute
  '/properties/buy/townhouse': typeof PropertiesBuyTownhouseRoute
  '/properties/rent/flat': typeof PropertiesRentFlatRoute
  '/properties/rent/house': typeof PropertiesRentHouseRoute
  '/properties/rent/townhouse': typeof PropertiesRentTownhouseRoute
  '/ru/nedvizhimost/razmestit-obyavlenie': typeof RuNedvizhimostRazmestitObyavlenieRoute
  '/ru/novostroyki/nedvizhimost': typeof RuNovostroykiNedvizhimostRoute
  '/villages/$communitySlug/$communityOffer': typeof VillagesCommunitySlugCommunityOfferRoute
  '/ru/agenti/$agentUsername': typeof RuAgentiAgentUsernameLazyRoute
  '/account/contacts': typeof AccountContactsIndexRoute
  '/account/deals': typeof AccountDealsIndexRoute
  '/account/leads': typeof AccountLeadsIndexRoute
  '/account/login': typeof AccountLoginIndexRoute
  '/account/tasks': typeof AccountTasksIndexRoute
  '/agents/exclusives': typeof AgentsExclusivesIndexRoute
  '/properties/buy': typeof PropertiesBuyIndexRoute
  '/properties/rent': typeof PropertiesRentIndexRoute
  '/ru/nedvizhimost': typeof RuNedvizhimostIndexRoute
  '/ru/novostroyki': typeof RuNovostroykiIndexRoute
  '/developments/$developmentSlug': typeof DevelopmentsDevelopmentSlugIndexLazyRoute
  '/properties/$propertyId': typeof PropertiesPropertyIdIndexLazyRoute
  '/ru/kottedzhnye-poselki': typeof RuKottedzhnyePoselkiIndexLazyRoute
  '/villages/$communitySlug': typeof VillagesCommunitySlugIndexLazyRoute
  '/properties/$propertyId/images/add': typeof PropertiesPropertyIdImagesAddRoute
  '/ru/account/deals/add': typeof RuAccountDealsAddRoute
  '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer': typeof RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute
  '/ru/nedvizhimost/kupit/dom': typeof RuNedvizhimostKupitDomRoute
  '/ru/nedvizhimost/kupit/kvartira': typeof RuNedvizhimostKupitKvartiraRoute
  '/ru/nedvizhimost/kupit/taunhaus': typeof RuNedvizhimostKupitTaunhausRoute
  '/ru/nedvizhimost/kupit/uchastok': typeof RuNedvizhimostKupitUchastokRoute
  '/ru/nedvizhimost/snyat/dom': typeof RuNedvizhimostSnyatDomRoute
  '/ru/nedvizhimost/snyat/kvartira': typeof RuNedvizhimostSnyatKvartiraRoute
  '/ru/nedvizhimost/snyat/taunhaus': typeof RuNedvizhimostSnyatTaunhausRoute
  '/ru/novostroyki/$developmentSlug/$developmentOffer': typeof RuNovostroykiDevelopmentSlugDevelopmentOfferRoute
  '/emails/subscribe/$key/confirmed': typeof EmailsSubscribeKeyConfirmedLazyRoute
  '/emails/unsubscribe/$key/confirmed': typeof EmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/contacts/$contactId': typeof AccountContactsContactIdIndexRoute
  '/account/deals/$dealId': typeof AccountDealsDealIdIndexRoute
  '/account/leads/$leadId': typeof AccountLeadsLeadIdIndexRoute
  '/account/tasks/$taskId': typeof AccountTasksTaskIdIndexRoute
  '/account/whatsapp/accounts': typeof AccountWhatsappAccountsIndexRoute
  '/properties/$propertyId/images': typeof PropertiesPropertyIdImagesIndexRoute
  '/ru/account/contacts': typeof RuAccountContactsIndexRoute
  '/ru/account/deals': typeof RuAccountDealsIndexRoute
  '/ru/account/leads': typeof RuAccountLeadsIndexRoute
  '/ru/account/login': typeof RuAccountLoginIndexRoute
  '/ru/account/tasks': typeof RuAccountTasksIndexRoute
  '/ru/agenti/ekskluzivi': typeof RuAgentiEkskluziviIndexRoute
  '/ru/nedvizhimost/kupit': typeof RuNedvizhimostKupitIndexRoute
  '/ru/nedvizhimost/snyat': typeof RuNedvizhimostSnyatIndexRoute
  '/agents/exclusives/$propertyId': typeof AgentsExclusivesPropertyIdIndexLazyRoute
  '/emails/subscribe/$key': typeof EmailsSubscribeKeyIndexLazyRoute
  '/emails/unsubscribe/$key': typeof EmailsUnsubscribeKeyIndexLazyRoute
  '/ru/kottedzhnye-poselki/$communitySlug': typeof RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId': typeof RuNedvizhimostPropertyIdIndexLazyRoute
  '/ru/novostroyki/$developmentSlug': typeof RuNovostroykiDevelopmentSlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId/images/add': typeof RuNedvizhimostPropertyIdImagesAddRoute
  '/ru/emails/subscribe/$key/confirmed': typeof RuEmailsSubscribeKeyConfirmedLazyRoute
  '/ru/emails/unsubscribe/$key/confirmed': typeof RuEmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/whatsapp/accounts/$whatsappAccountId': typeof AccountWhatsappAccountsWhatsappAccountIdIndexRoute
  '/ru/account/contacts/$contactId': typeof RuAccountContactsContactIdIndexRoute
  '/ru/account/deals/$dealId': typeof RuAccountDealsDealIdIndexRoute
  '/ru/account/leads/$leadId': typeof RuAccountLeadsLeadIdIndexRoute
  '/ru/account/tasks/$taskId': typeof RuAccountTasksTaskIdIndexRoute
  '/ru/account/whatsapp/accounts': typeof RuAccountWhatsappAccountsIndexRoute
  '/ru/nedvizhimost/$propertyId/images': typeof RuNedvizhimostPropertyIdImagesIndexRoute
  '/ru/agenti/ekskluzivi/$propertyId': typeof RuAgentiEkskluziviPropertyIdIndexLazyRoute
  '/ru/emails/subscribe/$key': typeof RuEmailsSubscribeKeyIndexLazyRoute
  '/ru/emails/unsubscribe/$key': typeof RuEmailsUnsubscribeKeyIndexLazyRoute
  '/ru/account/whatsapp/accounts/$whatsappAccountId': typeof RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '': typeof LayoutRoute
  '/$': typeof SplatLazyRoute
  '/investments': typeof InvestmentsLazyRoute
  '/privacy': typeof PrivacyLazyRoute
  '/selection': typeof SelectionLazyRoute
  '/developments/properties': typeof DevelopmentsPropertiesRoute
  '/properties/add': typeof PropertiesAddRoute
  '/agents/$agentUsername': typeof AgentsAgentUsernameLazyRoute
  '/ru/investicii': typeof RuInvesticiiLazyRoute
  '/ru/podbor': typeof RuPodborLazyRoute
  '/ru/privacy': typeof RuPrivacyLazyRoute
  '/developments': typeof DevelopmentsIndexRoute
  '/properties': typeof PropertiesIndexRoute
  '/ru': typeof RuIndexLazyRoute
  '/villages': typeof VillagesIndexLazyRoute
  '/account/deals/add': typeof AccountDealsAddRoute
  '/developments/$developmentSlug/$developmentOffer': typeof DevelopmentsDevelopmentSlugDevelopmentOfferRoute
  '/properties/buy/flat': typeof PropertiesBuyFlatRoute
  '/properties/buy/house': typeof PropertiesBuyHouseRoute
  '/properties/buy/land': typeof PropertiesBuyLandRoute
  '/properties/buy/townhouse': typeof PropertiesBuyTownhouseRoute
  '/properties/rent/flat': typeof PropertiesRentFlatRoute
  '/properties/rent/house': typeof PropertiesRentHouseRoute
  '/properties/rent/townhouse': typeof PropertiesRentTownhouseRoute
  '/ru/nedvizhimost/razmestit-obyavlenie': typeof RuNedvizhimostRazmestitObyavlenieRoute
  '/ru/novostroyki/nedvizhimost': typeof RuNovostroykiNedvizhimostRoute
  '/villages/$communitySlug/$communityOffer': typeof VillagesCommunitySlugCommunityOfferRoute
  '/ru/agenti/$agentUsername': typeof RuAgentiAgentUsernameLazyRoute
  '/account/contacts': typeof AccountContactsIndexRoute
  '/account/deals': typeof AccountDealsIndexRoute
  '/account/leads': typeof AccountLeadsIndexRoute
  '/account/login': typeof AccountLoginIndexRoute
  '/account/tasks': typeof AccountTasksIndexRoute
  '/agents/exclusives': typeof AgentsExclusivesIndexRoute
  '/properties/buy': typeof PropertiesBuyIndexRoute
  '/properties/rent': typeof PropertiesRentIndexRoute
  '/ru/nedvizhimost': typeof RuNedvizhimostIndexRoute
  '/ru/novostroyki': typeof RuNovostroykiIndexRoute
  '/developments/$developmentSlug': typeof DevelopmentsDevelopmentSlugIndexLazyRoute
  '/properties/$propertyId': typeof PropertiesPropertyIdIndexLazyRoute
  '/ru/kottedzhnye-poselki': typeof RuKottedzhnyePoselkiIndexLazyRoute
  '/villages/$communitySlug': typeof VillagesCommunitySlugIndexLazyRoute
  '/properties/$propertyId/images/add': typeof PropertiesPropertyIdImagesAddRoute
  '/ru/account/deals/add': typeof RuAccountDealsAddRoute
  '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer': typeof RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute
  '/ru/nedvizhimost/kupit/dom': typeof RuNedvizhimostKupitDomRoute
  '/ru/nedvizhimost/kupit/kvartira': typeof RuNedvizhimostKupitKvartiraRoute
  '/ru/nedvizhimost/kupit/taunhaus': typeof RuNedvizhimostKupitTaunhausRoute
  '/ru/nedvizhimost/kupit/uchastok': typeof RuNedvizhimostKupitUchastokRoute
  '/ru/nedvizhimost/snyat/dom': typeof RuNedvizhimostSnyatDomRoute
  '/ru/nedvizhimost/snyat/kvartira': typeof RuNedvizhimostSnyatKvartiraRoute
  '/ru/nedvizhimost/snyat/taunhaus': typeof RuNedvizhimostSnyatTaunhausRoute
  '/ru/novostroyki/$developmentSlug/$developmentOffer': typeof RuNovostroykiDevelopmentSlugDevelopmentOfferRoute
  '/emails/subscribe/$key/confirmed': typeof EmailsSubscribeKeyConfirmedLazyRoute
  '/emails/unsubscribe/$key/confirmed': typeof EmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/contacts/$contactId': typeof AccountContactsContactIdIndexRoute
  '/account/deals/$dealId': typeof AccountDealsDealIdIndexRoute
  '/account/leads/$leadId': typeof AccountLeadsLeadIdIndexRoute
  '/account/tasks/$taskId': typeof AccountTasksTaskIdIndexRoute
  '/account/whatsapp/accounts': typeof AccountWhatsappAccountsIndexRoute
  '/properties/$propertyId/images': typeof PropertiesPropertyIdImagesIndexRoute
  '/ru/account/contacts': typeof RuAccountContactsIndexRoute
  '/ru/account/deals': typeof RuAccountDealsIndexRoute
  '/ru/account/leads': typeof RuAccountLeadsIndexRoute
  '/ru/account/login': typeof RuAccountLoginIndexRoute
  '/ru/account/tasks': typeof RuAccountTasksIndexRoute
  '/ru/agenti/ekskluzivi': typeof RuAgentiEkskluziviIndexRoute
  '/ru/nedvizhimost/kupit': typeof RuNedvizhimostKupitIndexRoute
  '/ru/nedvizhimost/snyat': typeof RuNedvizhimostSnyatIndexRoute
  '/agents/exclusives/$propertyId': typeof AgentsExclusivesPropertyIdIndexLazyRoute
  '/emails/subscribe/$key': typeof EmailsSubscribeKeyIndexLazyRoute
  '/emails/unsubscribe/$key': typeof EmailsUnsubscribeKeyIndexLazyRoute
  '/ru/kottedzhnye-poselki/$communitySlug': typeof RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId': typeof RuNedvizhimostPropertyIdIndexLazyRoute
  '/ru/novostroyki/$developmentSlug': typeof RuNovostroykiDevelopmentSlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId/images/add': typeof RuNedvizhimostPropertyIdImagesAddRoute
  '/ru/emails/subscribe/$key/confirmed': typeof RuEmailsSubscribeKeyConfirmedLazyRoute
  '/ru/emails/unsubscribe/$key/confirmed': typeof RuEmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/whatsapp/accounts/$whatsappAccountId': typeof AccountWhatsappAccountsWhatsappAccountIdIndexRoute
  '/ru/account/contacts/$contactId': typeof RuAccountContactsContactIdIndexRoute
  '/ru/account/deals/$dealId': typeof RuAccountDealsDealIdIndexRoute
  '/ru/account/leads/$leadId': typeof RuAccountLeadsLeadIdIndexRoute
  '/ru/account/tasks/$taskId': typeof RuAccountTasksTaskIdIndexRoute
  '/ru/account/whatsapp/accounts': typeof RuAccountWhatsappAccountsIndexRoute
  '/ru/nedvizhimost/$propertyId/images': typeof RuNedvizhimostPropertyIdImagesIndexRoute
  '/ru/agenti/ekskluzivi/$propertyId': typeof RuAgentiEkskluziviPropertyIdIndexLazyRoute
  '/ru/emails/subscribe/$key': typeof RuEmailsSubscribeKeyIndexLazyRoute
  '/ru/emails/unsubscribe/$key': typeof RuEmailsUnsubscribeKeyIndexLazyRoute
  '/ru/account/whatsapp/accounts/$whatsappAccountId': typeof RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/_layout': typeof LayoutRoute
  '/$': typeof SplatLazyRoute
  '/investments': typeof InvestmentsLazyRoute
  '/privacy': typeof PrivacyLazyRoute
  '/selection': typeof SelectionLazyRoute
  '/developments/properties': typeof DevelopmentsPropertiesRoute
  '/properties/add': typeof PropertiesAddRoute
  '/agents/$agentUsername': typeof AgentsAgentUsernameLazyRoute
  '/ru/investicii': typeof RuInvesticiiLazyRoute
  '/ru/podbor': typeof RuPodborLazyRoute
  '/ru/privacy': typeof RuPrivacyLazyRoute
  '/developments/': typeof DevelopmentsIndexRoute
  '/properties/': typeof PropertiesIndexRoute
  '/ru/': typeof RuIndexLazyRoute
  '/villages/': typeof VillagesIndexLazyRoute
  '/account/deals/add': typeof AccountDealsAddRoute
  '/developments/$developmentSlug/$developmentOffer': typeof DevelopmentsDevelopmentSlugDevelopmentOfferRoute
  '/properties/buy/flat': typeof PropertiesBuyFlatRoute
  '/properties/buy/house': typeof PropertiesBuyHouseRoute
  '/properties/buy/land': typeof PropertiesBuyLandRoute
  '/properties/buy/townhouse': typeof PropertiesBuyTownhouseRoute
  '/properties/rent/flat': typeof PropertiesRentFlatRoute
  '/properties/rent/house': typeof PropertiesRentHouseRoute
  '/properties/rent/townhouse': typeof PropertiesRentTownhouseRoute
  '/ru/nedvizhimost/razmestit-obyavlenie': typeof RuNedvizhimostRazmestitObyavlenieRoute
  '/ru/novostroyki/nedvizhimost': typeof RuNovostroykiNedvizhimostRoute
  '/villages/$communitySlug/$communityOffer': typeof VillagesCommunitySlugCommunityOfferRoute
  '/ru/agenti/$agentUsername': typeof RuAgentiAgentUsernameLazyRoute
  '/account/contacts/': typeof AccountContactsIndexRoute
  '/account/deals/': typeof AccountDealsIndexRoute
  '/account/leads/': typeof AccountLeadsIndexRoute
  '/account/login/': typeof AccountLoginIndexRoute
  '/account/tasks/': typeof AccountTasksIndexRoute
  '/agents/exclusives/': typeof AgentsExclusivesIndexRoute
  '/properties/buy/': typeof PropertiesBuyIndexRoute
  '/properties/rent/': typeof PropertiesRentIndexRoute
  '/ru/nedvizhimost/': typeof RuNedvizhimostIndexRoute
  '/ru/novostroyki/': typeof RuNovostroykiIndexRoute
  '/developments/$developmentSlug/': typeof DevelopmentsDevelopmentSlugIndexLazyRoute
  '/properties/$propertyId/': typeof PropertiesPropertyIdIndexLazyRoute
  '/ru/kottedzhnye-poselki/': typeof RuKottedzhnyePoselkiIndexLazyRoute
  '/villages/$communitySlug/': typeof VillagesCommunitySlugIndexLazyRoute
  '/properties/$propertyId/images/add': typeof PropertiesPropertyIdImagesAddRoute
  '/ru/account/deals/add': typeof RuAccountDealsAddRoute
  '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer': typeof RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute
  '/ru/nedvizhimost/kupit/dom': typeof RuNedvizhimostKupitDomRoute
  '/ru/nedvizhimost/kupit/kvartira': typeof RuNedvizhimostKupitKvartiraRoute
  '/ru/nedvizhimost/kupit/taunhaus': typeof RuNedvizhimostKupitTaunhausRoute
  '/ru/nedvizhimost/kupit/uchastok': typeof RuNedvizhimostKupitUchastokRoute
  '/ru/nedvizhimost/snyat/dom': typeof RuNedvizhimostSnyatDomRoute
  '/ru/nedvizhimost/snyat/kvartira': typeof RuNedvizhimostSnyatKvartiraRoute
  '/ru/nedvizhimost/snyat/taunhaus': typeof RuNedvizhimostSnyatTaunhausRoute
  '/ru/novostroyki/$developmentSlug/$developmentOffer': typeof RuNovostroykiDevelopmentSlugDevelopmentOfferRoute
  '/emails/subscribe/$key/confirmed': typeof EmailsSubscribeKeyConfirmedLazyRoute
  '/emails/unsubscribe/$key/confirmed': typeof EmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/contacts/$contactId/': typeof AccountContactsContactIdIndexRoute
  '/account/deals/$dealId/': typeof AccountDealsDealIdIndexRoute
  '/account/leads/$leadId/': typeof AccountLeadsLeadIdIndexRoute
  '/account/tasks/$taskId/': typeof AccountTasksTaskIdIndexRoute
  '/account/whatsapp/accounts/': typeof AccountWhatsappAccountsIndexRoute
  '/properties/$propertyId/images/': typeof PropertiesPropertyIdImagesIndexRoute
  '/ru/account/contacts/': typeof RuAccountContactsIndexRoute
  '/ru/account/deals/': typeof RuAccountDealsIndexRoute
  '/ru/account/leads/': typeof RuAccountLeadsIndexRoute
  '/ru/account/login/': typeof RuAccountLoginIndexRoute
  '/ru/account/tasks/': typeof RuAccountTasksIndexRoute
  '/ru/agenti/ekskluzivi/': typeof RuAgentiEkskluziviIndexRoute
  '/ru/nedvizhimost/kupit/': typeof RuNedvizhimostKupitIndexRoute
  '/ru/nedvizhimost/snyat/': typeof RuNedvizhimostSnyatIndexRoute
  '/agents/exclusives/$propertyId/': typeof AgentsExclusivesPropertyIdIndexLazyRoute
  '/emails/subscribe/$key/': typeof EmailsSubscribeKeyIndexLazyRoute
  '/emails/unsubscribe/$key/': typeof EmailsUnsubscribeKeyIndexLazyRoute
  '/ru/kottedzhnye-poselki/$communitySlug/': typeof RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId/': typeof RuNedvizhimostPropertyIdIndexLazyRoute
  '/ru/novostroyki/$developmentSlug/': typeof RuNovostroykiDevelopmentSlugIndexLazyRoute
  '/ru/nedvizhimost/$propertyId/images/add': typeof RuNedvizhimostPropertyIdImagesAddRoute
  '/ru/emails/subscribe/$key/confirmed': typeof RuEmailsSubscribeKeyConfirmedLazyRoute
  '/ru/emails/unsubscribe/$key/confirmed': typeof RuEmailsUnsubscribeKeyConfirmedLazyRoute
  '/account/whatsapp/accounts/$whatsappAccountId/': typeof AccountWhatsappAccountsWhatsappAccountIdIndexRoute
  '/ru/account/contacts/$contactId/': typeof RuAccountContactsContactIdIndexRoute
  '/ru/account/deals/$dealId/': typeof RuAccountDealsDealIdIndexRoute
  '/ru/account/leads/$leadId/': typeof RuAccountLeadsLeadIdIndexRoute
  '/ru/account/tasks/$taskId/': typeof RuAccountTasksTaskIdIndexRoute
  '/ru/account/whatsapp/accounts/': typeof RuAccountWhatsappAccountsIndexRoute
  '/ru/nedvizhimost/$propertyId/images/': typeof RuNedvizhimostPropertyIdImagesIndexRoute
  '/ru/agenti/ekskluzivi/$propertyId/': typeof RuAgentiEkskluziviPropertyIdIndexLazyRoute
  '/ru/emails/subscribe/$key/': typeof RuEmailsSubscribeKeyIndexLazyRoute
  '/ru/emails/unsubscribe/$key/': typeof RuEmailsUnsubscribeKeyIndexLazyRoute
  '/ru/account/whatsapp/accounts/$whatsappAccountId/': typeof RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/$'
    | '/investments'
    | '/privacy'
    | '/selection'
    | '/developments/properties'
    | '/properties/add'
    | '/agents/$agentUsername'
    | '/ru/investicii'
    | '/ru/podbor'
    | '/ru/privacy'
    | '/developments'
    | '/properties'
    | '/ru'
    | '/villages'
    | '/account/deals/add'
    | '/developments/$developmentSlug/$developmentOffer'
    | '/properties/buy/flat'
    | '/properties/buy/house'
    | '/properties/buy/land'
    | '/properties/buy/townhouse'
    | '/properties/rent/flat'
    | '/properties/rent/house'
    | '/properties/rent/townhouse'
    | '/ru/nedvizhimost/razmestit-obyavlenie'
    | '/ru/novostroyki/nedvizhimost'
    | '/villages/$communitySlug/$communityOffer'
    | '/ru/agenti/$agentUsername'
    | '/account/contacts'
    | '/account/deals'
    | '/account/leads'
    | '/account/login'
    | '/account/tasks'
    | '/agents/exclusives'
    | '/properties/buy'
    | '/properties/rent'
    | '/ru/nedvizhimost'
    | '/ru/novostroyki'
    | '/developments/$developmentSlug'
    | '/properties/$propertyId'
    | '/ru/kottedzhnye-poselki'
    | '/villages/$communitySlug'
    | '/properties/$propertyId/images/add'
    | '/ru/account/deals/add'
    | '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
    | '/ru/nedvizhimost/kupit/dom'
    | '/ru/nedvizhimost/kupit/kvartira'
    | '/ru/nedvizhimost/kupit/taunhaus'
    | '/ru/nedvizhimost/kupit/uchastok'
    | '/ru/nedvizhimost/snyat/dom'
    | '/ru/nedvizhimost/snyat/kvartira'
    | '/ru/nedvizhimost/snyat/taunhaus'
    | '/ru/novostroyki/$developmentSlug/$developmentOffer'
    | '/emails/subscribe/$key/confirmed'
    | '/emails/unsubscribe/$key/confirmed'
    | '/account/contacts/$contactId'
    | '/account/deals/$dealId'
    | '/account/leads/$leadId'
    | '/account/tasks/$taskId'
    | '/account/whatsapp/accounts'
    | '/properties/$propertyId/images'
    | '/ru/account/contacts'
    | '/ru/account/deals'
    | '/ru/account/leads'
    | '/ru/account/login'
    | '/ru/account/tasks'
    | '/ru/agenti/ekskluzivi'
    | '/ru/nedvizhimost/kupit'
    | '/ru/nedvizhimost/snyat'
    | '/agents/exclusives/$propertyId'
    | '/emails/subscribe/$key'
    | '/emails/unsubscribe/$key'
    | '/ru/kottedzhnye-poselki/$communitySlug'
    | '/ru/nedvizhimost/$propertyId'
    | '/ru/novostroyki/$developmentSlug'
    | '/ru/nedvizhimost/$propertyId/images/add'
    | '/ru/emails/subscribe/$key/confirmed'
    | '/ru/emails/unsubscribe/$key/confirmed'
    | '/account/whatsapp/accounts/$whatsappAccountId'
    | '/ru/account/contacts/$contactId'
    | '/ru/account/deals/$dealId'
    | '/ru/account/leads/$leadId'
    | '/ru/account/tasks/$taskId'
    | '/ru/account/whatsapp/accounts'
    | '/ru/nedvizhimost/$propertyId/images'
    | '/ru/agenti/ekskluzivi/$propertyId'
    | '/ru/emails/subscribe/$key'
    | '/ru/emails/unsubscribe/$key'
    | '/ru/account/whatsapp/accounts/$whatsappAccountId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/$'
    | '/investments'
    | '/privacy'
    | '/selection'
    | '/developments/properties'
    | '/properties/add'
    | '/agents/$agentUsername'
    | '/ru/investicii'
    | '/ru/podbor'
    | '/ru/privacy'
    | '/developments'
    | '/properties'
    | '/ru'
    | '/villages'
    | '/account/deals/add'
    | '/developments/$developmentSlug/$developmentOffer'
    | '/properties/buy/flat'
    | '/properties/buy/house'
    | '/properties/buy/land'
    | '/properties/buy/townhouse'
    | '/properties/rent/flat'
    | '/properties/rent/house'
    | '/properties/rent/townhouse'
    | '/ru/nedvizhimost/razmestit-obyavlenie'
    | '/ru/novostroyki/nedvizhimost'
    | '/villages/$communitySlug/$communityOffer'
    | '/ru/agenti/$agentUsername'
    | '/account/contacts'
    | '/account/deals'
    | '/account/leads'
    | '/account/login'
    | '/account/tasks'
    | '/agents/exclusives'
    | '/properties/buy'
    | '/properties/rent'
    | '/ru/nedvizhimost'
    | '/ru/novostroyki'
    | '/developments/$developmentSlug'
    | '/properties/$propertyId'
    | '/ru/kottedzhnye-poselki'
    | '/villages/$communitySlug'
    | '/properties/$propertyId/images/add'
    | '/ru/account/deals/add'
    | '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
    | '/ru/nedvizhimost/kupit/dom'
    | '/ru/nedvizhimost/kupit/kvartira'
    | '/ru/nedvizhimost/kupit/taunhaus'
    | '/ru/nedvizhimost/kupit/uchastok'
    | '/ru/nedvizhimost/snyat/dom'
    | '/ru/nedvizhimost/snyat/kvartira'
    | '/ru/nedvizhimost/snyat/taunhaus'
    | '/ru/novostroyki/$developmentSlug/$developmentOffer'
    | '/emails/subscribe/$key/confirmed'
    | '/emails/unsubscribe/$key/confirmed'
    | '/account/contacts/$contactId'
    | '/account/deals/$dealId'
    | '/account/leads/$leadId'
    | '/account/tasks/$taskId'
    | '/account/whatsapp/accounts'
    | '/properties/$propertyId/images'
    | '/ru/account/contacts'
    | '/ru/account/deals'
    | '/ru/account/leads'
    | '/ru/account/login'
    | '/ru/account/tasks'
    | '/ru/agenti/ekskluzivi'
    | '/ru/nedvizhimost/kupit'
    | '/ru/nedvizhimost/snyat'
    | '/agents/exclusives/$propertyId'
    | '/emails/subscribe/$key'
    | '/emails/unsubscribe/$key'
    | '/ru/kottedzhnye-poselki/$communitySlug'
    | '/ru/nedvizhimost/$propertyId'
    | '/ru/novostroyki/$developmentSlug'
    | '/ru/nedvizhimost/$propertyId/images/add'
    | '/ru/emails/subscribe/$key/confirmed'
    | '/ru/emails/unsubscribe/$key/confirmed'
    | '/account/whatsapp/accounts/$whatsappAccountId'
    | '/ru/account/contacts/$contactId'
    | '/ru/account/deals/$dealId'
    | '/ru/account/leads/$leadId'
    | '/ru/account/tasks/$taskId'
    | '/ru/account/whatsapp/accounts'
    | '/ru/nedvizhimost/$propertyId/images'
    | '/ru/agenti/ekskluzivi/$propertyId'
    | '/ru/emails/subscribe/$key'
    | '/ru/emails/unsubscribe/$key'
    | '/ru/account/whatsapp/accounts/$whatsappAccountId'
  id:
    | '__root__'
    | '/'
    | '/_layout'
    | '/$'
    | '/investments'
    | '/privacy'
    | '/selection'
    | '/developments/properties'
    | '/properties/add'
    | '/agents/$agentUsername'
    | '/ru/investicii'
    | '/ru/podbor'
    | '/ru/privacy'
    | '/developments/'
    | '/properties/'
    | '/ru/'
    | '/villages/'
    | '/account/deals/add'
    | '/developments/$developmentSlug/$developmentOffer'
    | '/properties/buy/flat'
    | '/properties/buy/house'
    | '/properties/buy/land'
    | '/properties/buy/townhouse'
    | '/properties/rent/flat'
    | '/properties/rent/house'
    | '/properties/rent/townhouse'
    | '/ru/nedvizhimost/razmestit-obyavlenie'
    | '/ru/novostroyki/nedvizhimost'
    | '/villages/$communitySlug/$communityOffer'
    | '/ru/agenti/$agentUsername'
    | '/account/contacts/'
    | '/account/deals/'
    | '/account/leads/'
    | '/account/login/'
    | '/account/tasks/'
    | '/agents/exclusives/'
    | '/properties/buy/'
    | '/properties/rent/'
    | '/ru/nedvizhimost/'
    | '/ru/novostroyki/'
    | '/developments/$developmentSlug/'
    | '/properties/$propertyId/'
    | '/ru/kottedzhnye-poselki/'
    | '/villages/$communitySlug/'
    | '/properties/$propertyId/images/add'
    | '/ru/account/deals/add'
    | '/ru/kottedzhnye-poselki/$communitySlug/$communityOffer'
    | '/ru/nedvizhimost/kupit/dom'
    | '/ru/nedvizhimost/kupit/kvartira'
    | '/ru/nedvizhimost/kupit/taunhaus'
    | '/ru/nedvizhimost/kupit/uchastok'
    | '/ru/nedvizhimost/snyat/dom'
    | '/ru/nedvizhimost/snyat/kvartira'
    | '/ru/nedvizhimost/snyat/taunhaus'
    | '/ru/novostroyki/$developmentSlug/$developmentOffer'
    | '/emails/subscribe/$key/confirmed'
    | '/emails/unsubscribe/$key/confirmed'
    | '/account/contacts/$contactId/'
    | '/account/deals/$dealId/'
    | '/account/leads/$leadId/'
    | '/account/tasks/$taskId/'
    | '/account/whatsapp/accounts/'
    | '/properties/$propertyId/images/'
    | '/ru/account/contacts/'
    | '/ru/account/deals/'
    | '/ru/account/leads/'
    | '/ru/account/login/'
    | '/ru/account/tasks/'
    | '/ru/agenti/ekskluzivi/'
    | '/ru/nedvizhimost/kupit/'
    | '/ru/nedvizhimost/snyat/'
    | '/agents/exclusives/$propertyId/'
    | '/emails/subscribe/$key/'
    | '/emails/unsubscribe/$key/'
    | '/ru/kottedzhnye-poselki/$communitySlug/'
    | '/ru/nedvizhimost/$propertyId/'
    | '/ru/novostroyki/$developmentSlug/'
    | '/ru/nedvizhimost/$propertyId/images/add'
    | '/ru/emails/subscribe/$key/confirmed'
    | '/ru/emails/unsubscribe/$key/confirmed'
    | '/account/whatsapp/accounts/$whatsappAccountId/'
    | '/ru/account/contacts/$contactId/'
    | '/ru/account/deals/$dealId/'
    | '/ru/account/leads/$leadId/'
    | '/ru/account/tasks/$taskId/'
    | '/ru/account/whatsapp/accounts/'
    | '/ru/nedvizhimost/$propertyId/images/'
    | '/ru/agenti/ekskluzivi/$propertyId/'
    | '/ru/emails/subscribe/$key/'
    | '/ru/emails/unsubscribe/$key/'
    | '/ru/account/whatsapp/accounts/$whatsappAccountId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  LayoutRoute: typeof LayoutRoute
  SplatLazyRoute: typeof SplatLazyRoute
  InvestmentsLazyRoute: typeof InvestmentsLazyRoute
  PrivacyLazyRoute: typeof PrivacyLazyRoute
  SelectionLazyRoute: typeof SelectionLazyRoute
  DevelopmentsPropertiesRoute: typeof DevelopmentsPropertiesRoute
  PropertiesAddRoute: typeof PropertiesAddRoute
  AgentsAgentUsernameLazyRoute: typeof AgentsAgentUsernameLazyRoute
  RuInvesticiiLazyRoute: typeof RuInvesticiiLazyRoute
  RuPodborLazyRoute: typeof RuPodborLazyRoute
  RuPrivacyLazyRoute: typeof RuPrivacyLazyRoute
  DevelopmentsIndexRoute: typeof DevelopmentsIndexRoute
  PropertiesIndexRoute: typeof PropertiesIndexRoute
  RuIndexLazyRoute: typeof RuIndexLazyRoute
  VillagesIndexLazyRoute: typeof VillagesIndexLazyRoute
  AccountDealsAddRoute: typeof AccountDealsAddRoute
  DevelopmentsDevelopmentSlugDevelopmentOfferRoute: typeof DevelopmentsDevelopmentSlugDevelopmentOfferRoute
  PropertiesBuyFlatRoute: typeof PropertiesBuyFlatRoute
  PropertiesBuyHouseRoute: typeof PropertiesBuyHouseRoute
  PropertiesBuyLandRoute: typeof PropertiesBuyLandRoute
  PropertiesBuyTownhouseRoute: typeof PropertiesBuyTownhouseRoute
  PropertiesRentFlatRoute: typeof PropertiesRentFlatRoute
  PropertiesRentHouseRoute: typeof PropertiesRentHouseRoute
  PropertiesRentTownhouseRoute: typeof PropertiesRentTownhouseRoute
  RuNedvizhimostRazmestitObyavlenieRoute: typeof RuNedvizhimostRazmestitObyavlenieRoute
  RuNovostroykiNedvizhimostRoute: typeof RuNovostroykiNedvizhimostRoute
  VillagesCommunitySlugCommunityOfferRoute: typeof VillagesCommunitySlugCommunityOfferRoute
  RuAgentiAgentUsernameLazyRoute: typeof RuAgentiAgentUsernameLazyRoute
  AccountContactsIndexRoute: typeof AccountContactsIndexRoute
  AccountDealsIndexRoute: typeof AccountDealsIndexRoute
  AccountLeadsIndexRoute: typeof AccountLeadsIndexRoute
  AccountLoginIndexRoute: typeof AccountLoginIndexRoute
  AccountTasksIndexRoute: typeof AccountTasksIndexRoute
  AgentsExclusivesIndexRoute: typeof AgentsExclusivesIndexRoute
  PropertiesBuyIndexRoute: typeof PropertiesBuyIndexRoute
  PropertiesRentIndexRoute: typeof PropertiesRentIndexRoute
  RuNedvizhimostIndexRoute: typeof RuNedvizhimostIndexRoute
  RuNovostroykiIndexRoute: typeof RuNovostroykiIndexRoute
  DevelopmentsDevelopmentSlugIndexLazyRoute: typeof DevelopmentsDevelopmentSlugIndexLazyRoute
  PropertiesPropertyIdIndexLazyRoute: typeof PropertiesPropertyIdIndexLazyRoute
  RuKottedzhnyePoselkiIndexLazyRoute: typeof RuKottedzhnyePoselkiIndexLazyRoute
  VillagesCommunitySlugIndexLazyRoute: typeof VillagesCommunitySlugIndexLazyRoute
  PropertiesPropertyIdImagesAddRoute: typeof PropertiesPropertyIdImagesAddRoute
  RuAccountDealsAddRoute: typeof RuAccountDealsAddRoute
  RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute: typeof RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute
  RuNedvizhimostKupitDomRoute: typeof RuNedvizhimostKupitDomRoute
  RuNedvizhimostKupitKvartiraRoute: typeof RuNedvizhimostKupitKvartiraRoute
  RuNedvizhimostKupitTaunhausRoute: typeof RuNedvizhimostKupitTaunhausRoute
  RuNedvizhimostKupitUchastokRoute: typeof RuNedvizhimostKupitUchastokRoute
  RuNedvizhimostSnyatDomRoute: typeof RuNedvizhimostSnyatDomRoute
  RuNedvizhimostSnyatKvartiraRoute: typeof RuNedvizhimostSnyatKvartiraRoute
  RuNedvizhimostSnyatTaunhausRoute: typeof RuNedvizhimostSnyatTaunhausRoute
  RuNovostroykiDevelopmentSlugDevelopmentOfferRoute: typeof RuNovostroykiDevelopmentSlugDevelopmentOfferRoute
  EmailsSubscribeKeyConfirmedLazyRoute: typeof EmailsSubscribeKeyConfirmedLazyRoute
  EmailsUnsubscribeKeyConfirmedLazyRoute: typeof EmailsUnsubscribeKeyConfirmedLazyRoute
  AccountContactsContactIdIndexRoute: typeof AccountContactsContactIdIndexRoute
  AccountDealsDealIdIndexRoute: typeof AccountDealsDealIdIndexRoute
  AccountLeadsLeadIdIndexRoute: typeof AccountLeadsLeadIdIndexRoute
  AccountTasksTaskIdIndexRoute: typeof AccountTasksTaskIdIndexRoute
  AccountWhatsappAccountsIndexRoute: typeof AccountWhatsappAccountsIndexRoute
  PropertiesPropertyIdImagesIndexRoute: typeof PropertiesPropertyIdImagesIndexRoute
  RuAccountContactsIndexRoute: typeof RuAccountContactsIndexRoute
  RuAccountDealsIndexRoute: typeof RuAccountDealsIndexRoute
  RuAccountLeadsIndexRoute: typeof RuAccountLeadsIndexRoute
  RuAccountLoginIndexRoute: typeof RuAccountLoginIndexRoute
  RuAccountTasksIndexRoute: typeof RuAccountTasksIndexRoute
  RuAgentiEkskluziviIndexRoute: typeof RuAgentiEkskluziviIndexRoute
  RuNedvizhimostKupitIndexRoute: typeof RuNedvizhimostKupitIndexRoute
  RuNedvizhimostSnyatIndexRoute: typeof RuNedvizhimostSnyatIndexRoute
  AgentsExclusivesPropertyIdIndexLazyRoute: typeof AgentsExclusivesPropertyIdIndexLazyRoute
  EmailsSubscribeKeyIndexLazyRoute: typeof EmailsSubscribeKeyIndexLazyRoute
  EmailsUnsubscribeKeyIndexLazyRoute: typeof EmailsUnsubscribeKeyIndexLazyRoute
  RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute: typeof RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute
  RuNedvizhimostPropertyIdIndexLazyRoute: typeof RuNedvizhimostPropertyIdIndexLazyRoute
  RuNovostroykiDevelopmentSlugIndexLazyRoute: typeof RuNovostroykiDevelopmentSlugIndexLazyRoute
  RuNedvizhimostPropertyIdImagesAddRoute: typeof RuNedvizhimostPropertyIdImagesAddRoute
  RuEmailsSubscribeKeyConfirmedLazyRoute: typeof RuEmailsSubscribeKeyConfirmedLazyRoute
  RuEmailsUnsubscribeKeyConfirmedLazyRoute: typeof RuEmailsUnsubscribeKeyConfirmedLazyRoute
  AccountWhatsappAccountsWhatsappAccountIdIndexRoute: typeof AccountWhatsappAccountsWhatsappAccountIdIndexRoute
  RuAccountContactsContactIdIndexRoute: typeof RuAccountContactsContactIdIndexRoute
  RuAccountDealsDealIdIndexRoute: typeof RuAccountDealsDealIdIndexRoute
  RuAccountLeadsLeadIdIndexRoute: typeof RuAccountLeadsLeadIdIndexRoute
  RuAccountTasksTaskIdIndexRoute: typeof RuAccountTasksTaskIdIndexRoute
  RuAccountWhatsappAccountsIndexRoute: typeof RuAccountWhatsappAccountsIndexRoute
  RuNedvizhimostPropertyIdImagesIndexRoute: typeof RuNedvizhimostPropertyIdImagesIndexRoute
  RuAgentiEkskluziviPropertyIdIndexLazyRoute: typeof RuAgentiEkskluziviPropertyIdIndexLazyRoute
  RuEmailsSubscribeKeyIndexLazyRoute: typeof RuEmailsSubscribeKeyIndexLazyRoute
  RuEmailsUnsubscribeKeyIndexLazyRoute: typeof RuEmailsUnsubscribeKeyIndexLazyRoute
  RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute: typeof RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  LayoutRoute: LayoutRoute,
  SplatLazyRoute: SplatLazyRoute,
  InvestmentsLazyRoute: InvestmentsLazyRoute,
  PrivacyLazyRoute: PrivacyLazyRoute,
  SelectionLazyRoute: SelectionLazyRoute,
  DevelopmentsPropertiesRoute: DevelopmentsPropertiesRoute,
  PropertiesAddRoute: PropertiesAddRoute,
  AgentsAgentUsernameLazyRoute: AgentsAgentUsernameLazyRoute,
  RuInvesticiiLazyRoute: RuInvesticiiLazyRoute,
  RuPodborLazyRoute: RuPodborLazyRoute,
  RuPrivacyLazyRoute: RuPrivacyLazyRoute,
  DevelopmentsIndexRoute: DevelopmentsIndexRoute,
  PropertiesIndexRoute: PropertiesIndexRoute,
  RuIndexLazyRoute: RuIndexLazyRoute,
  VillagesIndexLazyRoute: VillagesIndexLazyRoute,
  AccountDealsAddRoute: AccountDealsAddRoute,
  DevelopmentsDevelopmentSlugDevelopmentOfferRoute:
    DevelopmentsDevelopmentSlugDevelopmentOfferRoute,
  PropertiesBuyFlatRoute: PropertiesBuyFlatRoute,
  PropertiesBuyHouseRoute: PropertiesBuyHouseRoute,
  PropertiesBuyLandRoute: PropertiesBuyLandRoute,
  PropertiesBuyTownhouseRoute: PropertiesBuyTownhouseRoute,
  PropertiesRentFlatRoute: PropertiesRentFlatRoute,
  PropertiesRentHouseRoute: PropertiesRentHouseRoute,
  PropertiesRentTownhouseRoute: PropertiesRentTownhouseRoute,
  RuNedvizhimostRazmestitObyavlenieRoute:
    RuNedvizhimostRazmestitObyavlenieRoute,
  RuNovostroykiNedvizhimostRoute: RuNovostroykiNedvizhimostRoute,
  VillagesCommunitySlugCommunityOfferRoute:
    VillagesCommunitySlugCommunityOfferRoute,
  RuAgentiAgentUsernameLazyRoute: RuAgentiAgentUsernameLazyRoute,
  AccountContactsIndexRoute: AccountContactsIndexRoute,
  AccountDealsIndexRoute: AccountDealsIndexRoute,
  AccountLeadsIndexRoute: AccountLeadsIndexRoute,
  AccountLoginIndexRoute: AccountLoginIndexRoute,
  AccountTasksIndexRoute: AccountTasksIndexRoute,
  AgentsExclusivesIndexRoute: AgentsExclusivesIndexRoute,
  PropertiesBuyIndexRoute: PropertiesBuyIndexRoute,
  PropertiesRentIndexRoute: PropertiesRentIndexRoute,
  RuNedvizhimostIndexRoute: RuNedvizhimostIndexRoute,
  RuNovostroykiIndexRoute: RuNovostroykiIndexRoute,
  DevelopmentsDevelopmentSlugIndexLazyRoute:
    DevelopmentsDevelopmentSlugIndexLazyRoute,
  PropertiesPropertyIdIndexLazyRoute: PropertiesPropertyIdIndexLazyRoute,
  RuKottedzhnyePoselkiIndexLazyRoute: RuKottedzhnyePoselkiIndexLazyRoute,
  VillagesCommunitySlugIndexLazyRoute: VillagesCommunitySlugIndexLazyRoute,
  PropertiesPropertyIdImagesAddRoute: PropertiesPropertyIdImagesAddRoute,
  RuAccountDealsAddRoute: RuAccountDealsAddRoute,
  RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute:
    RuKottedzhnyePoselkiCommunitySlugCommunityOfferRoute,
  RuNedvizhimostKupitDomRoute: RuNedvizhimostKupitDomRoute,
  RuNedvizhimostKupitKvartiraRoute: RuNedvizhimostKupitKvartiraRoute,
  RuNedvizhimostKupitTaunhausRoute: RuNedvizhimostKupitTaunhausRoute,
  RuNedvizhimostKupitUchastokRoute: RuNedvizhimostKupitUchastokRoute,
  RuNedvizhimostSnyatDomRoute: RuNedvizhimostSnyatDomRoute,
  RuNedvizhimostSnyatKvartiraRoute: RuNedvizhimostSnyatKvartiraRoute,
  RuNedvizhimostSnyatTaunhausRoute: RuNedvizhimostSnyatTaunhausRoute,
  RuNovostroykiDevelopmentSlugDevelopmentOfferRoute:
    RuNovostroykiDevelopmentSlugDevelopmentOfferRoute,
  EmailsSubscribeKeyConfirmedLazyRoute: EmailsSubscribeKeyConfirmedLazyRoute,
  EmailsUnsubscribeKeyConfirmedLazyRoute:
    EmailsUnsubscribeKeyConfirmedLazyRoute,
  AccountContactsContactIdIndexRoute: AccountContactsContactIdIndexRoute,
  AccountDealsDealIdIndexRoute: AccountDealsDealIdIndexRoute,
  AccountLeadsLeadIdIndexRoute: AccountLeadsLeadIdIndexRoute,
  AccountTasksTaskIdIndexRoute: AccountTasksTaskIdIndexRoute,
  AccountWhatsappAccountsIndexRoute: AccountWhatsappAccountsIndexRoute,
  PropertiesPropertyIdImagesIndexRoute: PropertiesPropertyIdImagesIndexRoute,
  RuAccountContactsIndexRoute: RuAccountContactsIndexRoute,
  RuAccountDealsIndexRoute: RuAccountDealsIndexRoute,
  RuAccountLeadsIndexRoute: RuAccountLeadsIndexRoute,
  RuAccountLoginIndexRoute: RuAccountLoginIndexRoute,
  RuAccountTasksIndexRoute: RuAccountTasksIndexRoute,
  RuAgentiEkskluziviIndexRoute: RuAgentiEkskluziviIndexRoute,
  RuNedvizhimostKupitIndexRoute: RuNedvizhimostKupitIndexRoute,
  RuNedvizhimostSnyatIndexRoute: RuNedvizhimostSnyatIndexRoute,
  AgentsExclusivesPropertyIdIndexLazyRoute:
    AgentsExclusivesPropertyIdIndexLazyRoute,
  EmailsSubscribeKeyIndexLazyRoute: EmailsSubscribeKeyIndexLazyRoute,
  EmailsUnsubscribeKeyIndexLazyRoute: EmailsUnsubscribeKeyIndexLazyRoute,
  RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute:
    RuKottedzhnyePoselkiCommunitySlugIndexLazyRoute,
  RuNedvizhimostPropertyIdIndexLazyRoute:
    RuNedvizhimostPropertyIdIndexLazyRoute,
  RuNovostroykiDevelopmentSlugIndexLazyRoute:
    RuNovostroykiDevelopmentSlugIndexLazyRoute,
  RuNedvizhimostPropertyIdImagesAddRoute:
    RuNedvizhimostPropertyIdImagesAddRoute,
  RuEmailsSubscribeKeyConfirmedLazyRoute:
    RuEmailsSubscribeKeyConfirmedLazyRoute,
  RuEmailsUnsubscribeKeyConfirmedLazyRoute:
    RuEmailsUnsubscribeKeyConfirmedLazyRoute,
  AccountWhatsappAccountsWhatsappAccountIdIndexRoute:
    AccountWhatsappAccountsWhatsappAccountIdIndexRoute,
  RuAccountContactsContactIdIndexRoute: RuAccountContactsContactIdIndexRoute,
  RuAccountDealsDealIdIndexRoute: RuAccountDealsDealIdIndexRoute,
  RuAccountLeadsLeadIdIndexRoute: RuAccountLeadsLeadIdIndexRoute,
  RuAccountTasksTaskIdIndexRoute: RuAccountTasksTaskIdIndexRoute,
  RuAccountWhatsappAccountsIndexRoute: RuAccountWhatsappAccountsIndexRoute,
  RuNedvizhimostPropertyIdImagesIndexRoute:
    RuNedvizhimostPropertyIdImagesIndexRoute,
  RuAgentiEkskluziviPropertyIdIndexLazyRoute:
    RuAgentiEkskluziviPropertyIdIndexLazyRoute,
  RuEmailsSubscribeKeyIndexLazyRoute: RuEmailsSubscribeKeyIndexLazyRoute,
  RuEmailsUnsubscribeKeyIndexLazyRoute: RuEmailsUnsubscribeKeyIndexLazyRoute,
  RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute:
    RuAccountWhatsappAccountsWhatsappAccountIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_layout",
        "/$",
        "/investments",
        "/privacy",
        "/selection",
        "/developments/properties",
        "/properties/add",
        "/agents/$agentUsername",
        "/ru/investicii",
        "/ru/podbor",
        "/ru/privacy",
        "/developments/",
        "/properties/",
        "/ru/",
        "/villages/",
        "/account/deals/add",
        "/developments/$developmentSlug/$developmentOffer",
        "/properties/buy/flat",
        "/properties/buy/house",
        "/properties/buy/land",
        "/properties/buy/townhouse",
        "/properties/rent/flat",
        "/properties/rent/house",
        "/properties/rent/townhouse",
        "/ru/nedvizhimost/razmestit-obyavlenie",
        "/ru/novostroyki/nedvizhimost",
        "/villages/$communitySlug/$communityOffer",
        "/ru/agenti/$agentUsername",
        "/account/contacts/",
        "/account/deals/",
        "/account/leads/",
        "/account/login/",
        "/account/tasks/",
        "/agents/exclusives/",
        "/properties/buy/",
        "/properties/rent/",
        "/ru/nedvizhimost/",
        "/ru/novostroyki/",
        "/developments/$developmentSlug/",
        "/properties/$propertyId/",
        "/ru/kottedzhnye-poselki/",
        "/villages/$communitySlug/",
        "/properties/$propertyId/images/add",
        "/ru/account/deals/add",
        "/ru/kottedzhnye-poselki/$communitySlug/$communityOffer",
        "/ru/nedvizhimost/kupit/dom",
        "/ru/nedvizhimost/kupit/kvartira",
        "/ru/nedvizhimost/kupit/taunhaus",
        "/ru/nedvizhimost/kupit/uchastok",
        "/ru/nedvizhimost/snyat/dom",
        "/ru/nedvizhimost/snyat/kvartira",
        "/ru/nedvizhimost/snyat/taunhaus",
        "/ru/novostroyki/$developmentSlug/$developmentOffer",
        "/emails/subscribe/$key/confirmed",
        "/emails/unsubscribe/$key/confirmed",
        "/account/contacts/$contactId/",
        "/account/deals/$dealId/",
        "/account/leads/$leadId/",
        "/account/tasks/$taskId/",
        "/account/whatsapp/accounts/",
        "/properties/$propertyId/images/",
        "/ru/account/contacts/",
        "/ru/account/deals/",
        "/ru/account/leads/",
        "/ru/account/login/",
        "/ru/account/tasks/",
        "/ru/agenti/ekskluzivi/",
        "/ru/nedvizhimost/kupit/",
        "/ru/nedvizhimost/snyat/",
        "/agents/exclusives/$propertyId/",
        "/emails/subscribe/$key/",
        "/emails/unsubscribe/$key/",
        "/ru/kottedzhnye-poselki/$communitySlug/",
        "/ru/nedvizhimost/$propertyId/",
        "/ru/novostroyki/$developmentSlug/",
        "/ru/nedvizhimost/$propertyId/images/add",
        "/ru/emails/subscribe/$key/confirmed",
        "/ru/emails/unsubscribe/$key/confirmed",
        "/account/whatsapp/accounts/$whatsappAccountId/",
        "/ru/account/contacts/$contactId/",
        "/ru/account/deals/$dealId/",
        "/ru/account/leads/$leadId/",
        "/ru/account/tasks/$taskId/",
        "/ru/account/whatsapp/accounts/",
        "/ru/nedvizhimost/$propertyId/images/",
        "/ru/agenti/ekskluzivi/$propertyId/",
        "/ru/emails/subscribe/$key/",
        "/ru/emails/unsubscribe/$key/",
        "/ru/account/whatsapp/accounts/$whatsappAccountId/"
      ]
    },
    "/": {
      "filePath": "index.lazy.ts"
    },
    "/_layout": {
      "filePath": "_layout.tsx"
    },
    "/$": {
      "filePath": "$.lazy.ts"
    },
    "/investments": {
      "filePath": "investments.lazy.ts"
    },
    "/privacy": {
      "filePath": "privacy.lazy.ts"
    },
    "/selection": {
      "filePath": "selection.lazy.ts"
    },
    "/developments/properties": {
      "filePath": "developments/properties.ts"
    },
    "/properties/add": {
      "filePath": "properties/add.ts"
    },
    "/agents/$agentUsername": {
      "filePath": "agents/$agentUsername.lazy.ts"
    },
    "/ru/investicii": {
      "filePath": "ru/investicii.lazy.ts"
    },
    "/ru/podbor": {
      "filePath": "ru/podbor.lazy.ts"
    },
    "/ru/privacy": {
      "filePath": "ru/privacy.lazy.ts"
    },
    "/developments/": {
      "filePath": "developments/index.ts"
    },
    "/properties/": {
      "filePath": "properties/index.ts"
    },
    "/ru/": {
      "filePath": "ru/index.lazy.ts"
    },
    "/villages/": {
      "filePath": "villages/index.lazy.ts"
    },
    "/account/deals/add": {
      "filePath": "account/deals/add.ts"
    },
    "/developments/$developmentSlug/$developmentOffer": {
      "filePath": "developments/$developmentSlug/$developmentOffer.ts"
    },
    "/properties/buy/flat": {
      "filePath": "properties/buy/flat.ts"
    },
    "/properties/buy/house": {
      "filePath": "properties/buy/house.ts"
    },
    "/properties/buy/land": {
      "filePath": "properties/buy/land.ts"
    },
    "/properties/buy/townhouse": {
      "filePath": "properties/buy/townhouse.ts"
    },
    "/properties/rent/flat": {
      "filePath": "properties/rent/flat.ts"
    },
    "/properties/rent/house": {
      "filePath": "properties/rent/house.ts"
    },
    "/properties/rent/townhouse": {
      "filePath": "properties/rent/townhouse.ts"
    },
    "/ru/nedvizhimost/razmestit-obyavlenie": {
      "filePath": "ru/nedvizhimost/razmestit-obyavlenie.ts"
    },
    "/ru/novostroyki/nedvizhimost": {
      "filePath": "ru/novostroyki/nedvizhimost.ts"
    },
    "/villages/$communitySlug/$communityOffer": {
      "filePath": "villages/$communitySlug/$communityOffer.ts"
    },
    "/ru/agenti/$agentUsername": {
      "filePath": "ru/agenti/$agentUsername.lazy.ts"
    },
    "/account/contacts/": {
      "filePath": "account/contacts/index.ts"
    },
    "/account/deals/": {
      "filePath": "account/deals/index.ts"
    },
    "/account/leads/": {
      "filePath": "account/leads/index.ts"
    },
    "/account/login/": {
      "filePath": "account/login/index.ts"
    },
    "/account/tasks/": {
      "filePath": "account/tasks/index.ts"
    },
    "/agents/exclusives/": {
      "filePath": "agents/exclusives/index.ts"
    },
    "/properties/buy/": {
      "filePath": "properties/buy/index.ts"
    },
    "/properties/rent/": {
      "filePath": "properties/rent/index.ts"
    },
    "/ru/nedvizhimost/": {
      "filePath": "ru/nedvizhimost/index.ts"
    },
    "/ru/novostroyki/": {
      "filePath": "ru/novostroyki/index.ts"
    },
    "/developments/$developmentSlug/": {
      "filePath": "developments/$developmentSlug/index.lazy.ts"
    },
    "/properties/$propertyId/": {
      "filePath": "properties/$propertyId/index.lazy.ts"
    },
    "/ru/kottedzhnye-poselki/": {
      "filePath": "ru/kottedzhnye-poselki/index.lazy.ts"
    },
    "/villages/$communitySlug/": {
      "filePath": "villages/$communitySlug/index.lazy.ts"
    },
    "/properties/$propertyId/images/add": {
      "filePath": "properties/$propertyId/images/add.ts"
    },
    "/ru/account/deals/add": {
      "filePath": "ru/account/deals/add.ts"
    },
    "/ru/kottedzhnye-poselki/$communitySlug/$communityOffer": {
      "filePath": "ru/kottedzhnye-poselki/$communitySlug/$communityOffer.ts"
    },
    "/ru/nedvizhimost/kupit/dom": {
      "filePath": "ru/nedvizhimost/kupit/dom.ts"
    },
    "/ru/nedvizhimost/kupit/kvartira": {
      "filePath": "ru/nedvizhimost/kupit/kvartira.ts"
    },
    "/ru/nedvizhimost/kupit/taunhaus": {
      "filePath": "ru/nedvizhimost/kupit/taunhaus.ts"
    },
    "/ru/nedvizhimost/kupit/uchastok": {
      "filePath": "ru/nedvizhimost/kupit/uchastok.ts"
    },
    "/ru/nedvizhimost/snyat/dom": {
      "filePath": "ru/nedvizhimost/snyat/dom.ts"
    },
    "/ru/nedvizhimost/snyat/kvartira": {
      "filePath": "ru/nedvizhimost/snyat/kvartira.ts"
    },
    "/ru/nedvizhimost/snyat/taunhaus": {
      "filePath": "ru/nedvizhimost/snyat/taunhaus.ts"
    },
    "/ru/novostroyki/$developmentSlug/$developmentOffer": {
      "filePath": "ru/novostroyki/$developmentSlug/$developmentOffer.ts"
    },
    "/emails/subscribe/$key/confirmed": {
      "filePath": "emails/subscribe/$key/confirmed.lazy.ts"
    },
    "/emails/unsubscribe/$key/confirmed": {
      "filePath": "emails/unsubscribe/$key/confirmed.lazy.ts"
    },
    "/account/contacts/$contactId/": {
      "filePath": "account/contacts/$contactId/index.ts"
    },
    "/account/deals/$dealId/": {
      "filePath": "account/deals/$dealId/index.ts"
    },
    "/account/leads/$leadId/": {
      "filePath": "account/leads/$leadId/index.ts"
    },
    "/account/tasks/$taskId/": {
      "filePath": "account/tasks/$taskId/index.ts"
    },
    "/account/whatsapp/accounts/": {
      "filePath": "account/whatsapp/accounts/index.ts"
    },
    "/properties/$propertyId/images/": {
      "filePath": "properties/$propertyId/images/index.ts"
    },
    "/ru/account/contacts/": {
      "filePath": "ru/account/contacts/index.ts"
    },
    "/ru/account/deals/": {
      "filePath": "ru/account/deals/index.ts"
    },
    "/ru/account/leads/": {
      "filePath": "ru/account/leads/index.ts"
    },
    "/ru/account/login/": {
      "filePath": "ru/account/login/index.ts"
    },
    "/ru/account/tasks/": {
      "filePath": "ru/account/tasks/index.ts"
    },
    "/ru/agenti/ekskluzivi/": {
      "filePath": "ru/agenti/ekskluzivi/index.ts"
    },
    "/ru/nedvizhimost/kupit/": {
      "filePath": "ru/nedvizhimost/kupit/index.ts"
    },
    "/ru/nedvizhimost/snyat/": {
      "filePath": "ru/nedvizhimost/snyat/index.ts"
    },
    "/agents/exclusives/$propertyId/": {
      "filePath": "agents/exclusives/$propertyId/index.lazy.ts"
    },
    "/emails/subscribe/$key/": {
      "filePath": "emails/subscribe/$key/index.lazy.ts"
    },
    "/emails/unsubscribe/$key/": {
      "filePath": "emails/unsubscribe/$key/index.lazy.ts"
    },
    "/ru/kottedzhnye-poselki/$communitySlug/": {
      "filePath": "ru/kottedzhnye-poselki/$communitySlug/index.lazy.ts"
    },
    "/ru/nedvizhimost/$propertyId/": {
      "filePath": "ru/nedvizhimost/$propertyId/index.lazy.ts"
    },
    "/ru/novostroyki/$developmentSlug/": {
      "filePath": "ru/novostroyki/$developmentSlug/index.lazy.ts"
    },
    "/ru/nedvizhimost/$propertyId/images/add": {
      "filePath": "ru/nedvizhimost/$propertyId/images/add.ts"
    },
    "/ru/emails/subscribe/$key/confirmed": {
      "filePath": "ru/emails/subscribe/$key/confirmed.lazy.ts"
    },
    "/ru/emails/unsubscribe/$key/confirmed": {
      "filePath": "ru/emails/unsubscribe/$key/confirmed.lazy.ts"
    },
    "/account/whatsapp/accounts/$whatsappAccountId/": {
      "filePath": "account/whatsapp/accounts/$whatsappAccountId/index.ts"
    },
    "/ru/account/contacts/$contactId/": {
      "filePath": "ru/account/contacts/$contactId/index.ts"
    },
    "/ru/account/deals/$dealId/": {
      "filePath": "ru/account/deals/$dealId/index.ts"
    },
    "/ru/account/leads/$leadId/": {
      "filePath": "ru/account/leads/$leadId/index.ts"
    },
    "/ru/account/tasks/$taskId/": {
      "filePath": "ru/account/tasks/$taskId/index.ts"
    },
    "/ru/account/whatsapp/accounts/": {
      "filePath": "ru/account/whatsapp/accounts/index.ts"
    },
    "/ru/nedvizhimost/$propertyId/images/": {
      "filePath": "ru/nedvizhimost/$propertyId/images/index.ts"
    },
    "/ru/agenti/ekskluzivi/$propertyId/": {
      "filePath": "ru/agenti/ekskluzivi/$propertyId/index.lazy.ts"
    },
    "/ru/emails/subscribe/$key/": {
      "filePath": "ru/emails/subscribe/$key/index.lazy.ts"
    },
    "/ru/emails/unsubscribe/$key/": {
      "filePath": "ru/emails/unsubscribe/$key/index.lazy.ts"
    },
    "/ru/account/whatsapp/accounts/$whatsappAccountId/": {
      "filePath": "ru/account/whatsapp/accounts/$whatsappAccountId/index.ts"
    }
  }
}
ROUTE_MANIFEST_END */
