import * as React from "react";
import {Dialog, DialogBackdrop, Transition} from "@headlessui/react";
import {useCallActionStore} from "@sputnikestate/store";
import Call from "./components/Call";
import Request from "./components/Request";

const CallActionModal: React.FC = () => {
    const opened = useCallActionStore((state) => state.opened);
    const phone = useCallActionStore((state) => state.phone);

    const handleCallModal = useCallActionStore((state) => state.handleCallModal);

    const cancelButtonRef = React.useRef<HTMLInputElement>(null);

    return (
        <Transition show={opened} as={React.Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={() => handleCallModal()}
            >
                <DialogBackdrop
                    transition={true}
                    className="fixed inset-0 bg-black/40 transition duration-300 ease-out backdrop-blur-xs data-data-leave:ease-in data-data-leave:duration-200 data-closed:backdrop-blur-none data-closed:opacity-0"
                />
                {phone ? (
                    <Call cancelButtonRef={cancelButtonRef}/>
                ) : (
                    <Request cancelButtonRef={cancelButtonRef}/>
                )}
            </Dialog>
        </Transition>
    );
};

export default CallActionModal;
