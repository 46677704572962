import {createFileRoute, redirect} from '@tanstack/react-router';
import i18n from '@locale/i18n';

export const Route = createFileRoute('/account/login/')({
    beforeLoad: async ({context}) => {
        if (context.user.loggedIn) {
            if (!i18n.hasLoadedNamespace('links')) {
                await i18n.loadNamespaces('links');
            }
            throw redirect({
                to: i18n.t('home.absolute', {ns: 'links'}),
                replace: true,
            });
        }
    },
});
