import * as React from "react";
import {useServer} from "../../../hooks/useServer";
import Link from "../../../components/composites/links/Link";
import {useLandingsLinks} from "../../../hooks/useLandingsLinks";

const Page: React.FC = () => {
    const server = useServer();
    const {homeLinkOptions} = useLandingsLinks();

    if (server) server.statusCode = 404;

    return (
        <div
            className="flex justify-center items-center h-screen px-6"
        >
            <div className="flex flex-col sm:items-center gap-14 sm:gap-20 text-center">
                <div className="flex flex-col gap-4 sm:gap-8 max-w-(--breakpoint-sm)">
                    <div className="text-3xl sm:text-4xl lg:text-5xl text-slate-900 font-black tracking-tight">
                        Страница не найдена
                    </div>
                    <div className="sm:text-lg font-medium">
                        Возможно, у вас опечатка в адресе страницы, или её просто не существует.
                    </div>
                </div>
                <div>
                    <Link
                        {...homeLinkOptions()}
                        className="flex h-14 sm:w-80 items-center justify-center gap-3 rounded-full bg-blue-500 text-base font-semibold text-white hover:bg-blue-400"
                    >
                        Перейти на главную
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default Page;
