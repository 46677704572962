import 'rc-slider/assets/index.css';
import './locale/i18n';
import './index.css';
import * as React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import {HelmetProvider} from "react-helmet-async";
import * as Sentry from "@sentry/react";
import {CookiesProvider} from "react-cookie";
import {GraphProvider} from "@sputnikestate/graph";
import {client} from "./graph";
import {useSSR} from "react-i18next";
import {getLocaleState} from "@locale/helpers";
import ErrorFallback from "./components/composites/views/error";
import {RouterProvider} from "@tanstack/react-router";
import {AccountProvider, useAccount} from "./hooks";
import {createRouter} from "@routes/index";

declare global {
    interface Window {
        [key: string]: any;
    }
}

if (import.meta.env.PROD) Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_SENTRY_RELEASE
});

const router = createRouter();

const Inner: React.FC = () => {
    const {user} = useAccount();

    return <RouterProvider router={router} context={{user}}/>;
};

const Root: React.FC = () => {
    return (
        <Sentry.ErrorBoundary fallback={({error}) => <ErrorFallback error={error}/>}>
            <CookiesProvider>
                <GraphProvider client={client}>
                    <HelmetProvider>
                        <AccountProvider>
                            <Inner/>
                        </AccountProvider>
                    </HelmetProvider>
                </GraphProvider>
            </CookiesProvider>
        </Sentry.ErrorBoundary>
    )
};

const container = document.getElementById('root') as HTMLElement;

if (container.hasChildNodes()) {
    const {initialI18nStore, initialLanguage} = getLocaleState();

    const ServerRoot: React.FC = () => {
        useSSR(initialI18nStore, initialLanguage);
        return <Root/>;
    };

    hydrateRoot(container, <ServerRoot/>);
} else {
    const root = createRoot(container);
    root.render(<Root/>);
}
