import {linkOptions, useRouter} from "@tanstack/react-router";
import {Locale, type LocaleType} from "@locale/constants";
import {useTranslation} from "react-i18next";

interface LangLinkOptionsProps {
    lng: LocaleType;
}

interface OptionalLangLinkOptionsProps {
    lng?: LocaleType;
}

export const useLandingsLinks = () => {
    const router = useRouter();
    const {i18n} = useTranslation();

    const getHomeRoute = ({lng}: LangLinkOptionsProps) => {
        if (lng === Locale.RU) return '/ru';
        return '/';
    };

    const homeRoute = ({lng}: OptionalLangLinkOptionsProps) => {
        if (lng) return getHomeRoute({lng});
        return getHomeRoute({lng: i18n.language});
    };

    const homeLinkOptions = (props?: OptionalLangLinkOptionsProps) => {
        const {lng} = props || {};
        return linkOptions({
            to: homeRoute({lng}),
        });
    };

    const buildHomeLocation = (props?: OptionalLangLinkOptionsProps) => router.buildLocation(
        homeLinkOptions(props)
    );

    const getPrivacyRoute = ({lng}: LangLinkOptionsProps) => {
        if (lng === Locale.RU) return '/ru/privacy';
        return '/privacy';
    };

    const privacyRoute = ({lng}: OptionalLangLinkOptionsProps) => {
        if (lng) return getPrivacyRoute({lng});
        return getPrivacyRoute({lng: i18n.language});
    };

    const privacyLinkOptions = (props?: OptionalLangLinkOptionsProps) => {
        const {lng} = props || {};
        return linkOptions({
            to: privacyRoute({lng}),
        });
    };

    const buildPrivacyLocation = (props: OptionalLangLinkOptionsProps) => router.buildLocation(
        privacyLinkOptions(props)
    );

    const getSelectionRoute = ({lng}: LangLinkOptionsProps) => {
        if (lng === Locale.RU) return '/ru/podbor';
        return '/selection';
    };

    const selectionRoute = ({lng}: OptionalLangLinkOptionsProps) => {
        if (lng) return getSelectionRoute({lng});
        return getSelectionRoute({lng: i18n.language});
    };

    const selectionLinkOptions = (props?: OptionalLangLinkOptionsProps) => {
        const {lng} = props || {};
        return linkOptions({
            to: selectionRoute({lng}),
        });
    };

    const buildSelectionLocation = (props: OptionalLangLinkOptionsProps) => router.buildLocation(
        selectionLinkOptions(props)
    );

    return {
        homeRoute,
        homeLinkOptions,
        buildHomeLocation,
        privacyRoute,
        privacyLinkOptions,
        buildPrivacyLocation,
        selectionRoute,
        selectionLinkOptions,
        buildSelectionLocation,
    };
};
