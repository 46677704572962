import * as React from "react";
import {DialogPanel, DialogTitle, TransitionChild} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {useQRCodeGenerate} from "@sputnikestate/graph";
import {useCallActionStore} from "@sputnikestate/store";

interface ICall {
    cancelButtonRef: any;
}

const Call: React.FC<ICall> = ({cancelButtonRef}) => {
    const {t} = useTranslation('common');

    const phone = useCallActionStore((state) => state.phone);
    const phoneFormatted = useCallActionStore((state) => state.phoneFormatted);

    const handleCallModal = useCallActionStore((state) => state.handleCallModal);

    const {getQRCode, data} = useQRCodeGenerate();

    React.useEffect(
        () => {
            void getQRCode({
                variables: {
                    content: `tel:${phone}`
                }
            });
        },
        [phone]
    );

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 md:p-0 md:items-center">
                <TransitionChild
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                    enterTo="opacity-100 translate-y-0 md:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 md:scale-100"
                    leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                    <DialogPanel
                        className="relative transform overflow-hidden rounded-2xl bg-white transition-all md:my-8 w-full md:max-w-lg"
                    >
                        <div className="bg-white pt-3 px-9">
                            <div className="flex flex-col">
                                <div className="mt-5 md:mt-4 text-center w-full">
                                    <a href={`tel:${phone}`}>
                                        <DialogTitle
                                            as="h3"
                                            className="text-xl md:text-2xl font-bold leading-6 text-gray-900"
                                        >
                                            {phoneFormatted}
                                        </DialogTitle>
                                    </a>
                                    <div className="mt-1 text-sm text-gray-500">
                                        {t('onlyCallsPhone')}
                                    </div>
                                </div>
                                {data && (
                                    <div className="flex flex-col items-center mt-7">
                                        <img
                                            className="w-32 h-32 mt-0.5"
                                            src={`data:image/png;base64,${data.qr.generateQrCode.code}`}
                                        />
                                        <p className="mt-2.5 text-sm text-gray-500 text-center">
                                            {t('quickPhoneDial')}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-center py-7">
                            <button
                                type="button"
                                className="flex w-64 items-center justify-center rounded-full border border-transparent bg-blue-500 my-0.5 py-3 px-8 text-base font-semibold text-white hover:bg-blue-400 focus:outline-hidden"
                                onClick={() => handleCallModal()}
                                ref={cancelButtonRef}
                            >
                                {t('close')}
                            </button>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    );
};

export default Call;
