import * as React from "react";
import {Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild} from "@headlessui/react";
import {twMerge} from "tailwind-merge";
import {useEditorStore} from "../../../../../store/useEditorStore";
import {useShallow} from "zustand/react/shallow";

const PropertyUpdater = React.lazy(() => import('../../../../../pages/property/Property/components/Updater'));
const DealUpdater = React.lazy(() => import('../../../../../pages/deal/Deal/components/Updater'));
const TaskUpdater = React.lazy(() => import('../../../../../pages/task/Task/components/Updater'));
const LeadUpdater = React.lazy(() => import('../../../../../pages/lead/Lead/components/Updater'));
const ContactUpdater = React.lazy(() => import('../../../../../pages/contact/Contact/sections/Name/Updater'));
const ContactPhoneCreator = React.lazy(() => import('../../../../../pages/contact/Contact/sections/Phones/Creator'));
const ContactEmailCreator = React.lazy(() => import('../../../../../pages/contact/Contact/sections/Emails/Creator'));
const DealContactUpdater = React.lazy(() => import('../../../../../pages/deal/Deal/sections/Contacts/Updater'));
const DealContactCreator = React.lazy(() => import('../../../../../pages/deal/Deal/sections/Contacts/Creator'));

const EditorOverlay: React.FC = () => {
    const closeButtonRef = React.useRef<HTMLButtonElement>(null);

    const {
        type,
        containerClassName,
        closeEditor,
    } = useEditorStore(
        useShallow((state) => ({
            type: state.type,
            containerClassName: state.containerClassName,
            closeEditor: state.closeEditor,
        })),
    );

    return (
        <Transition show={!!type} as={React.Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={closeButtonRef}
                onClose={closeEditor}
                unmount={true}
            >
                <DialogBackdrop
                    transition={true}
                    className="fixed inset-0 bg-black/40 transition duration-300 ease-out backdrop-blur-xs data-data-leave:ease-in data-data-leave:duration-200 data-closed:backdrop-blur-none data-closed:opacity-0"
                />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 md:p-0 md:items-center">
                        <TransitionChild
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                className={twMerge(
                                    'relative transform rounded-2xl bg-white text-left shadow-xl transition-all sm:m-8 sm:w-full sm:max-w-xl',
                                    containerClassName
                                )}
                            >
                                <React.Suspense>
                                    {type === 'property__update' && (
                                        <PropertyUpdater closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'deal__update' && (
                                        <DealUpdater closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'task__update' && (
                                        <TaskUpdater closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'lead__update' && (
                                        <LeadUpdater closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'contact__update' && (
                                        <ContactUpdater closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'contact__phone__create' && (
                                        <ContactPhoneCreator closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'contact__email__create' && (
                                        <ContactEmailCreator closeButtonRef={closeButtonRef}/>
                                    )}
                                    {type === 'property__contact__update' && (
                                        <DealContactUpdater
                                            type="properties"
                                            closeButtonRef={closeButtonRef}
                                        />
                                    )}
                                    {type === 'deal__contact__update' && (
                                        <DealContactUpdater
                                            type="deals"
                                            closeButtonRef={closeButtonRef}
                                        />
                                    )}
                                    {type === 'property__contact__create' && (
                                        <DealContactCreator
                                            type="properties"
                                            closeButtonRef={closeButtonRef}
                                        />
                                    )}
                                    {type === 'deal__contact__create' && (
                                        <DealContactCreator
                                            type="deals"
                                            closeButtonRef={closeButtonRef}
                                        />
                                    )}
                                </React.Suspense>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditorOverlay;
