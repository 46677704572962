import React from "react";
import {useBreakpoints} from "../../hooks";
import {useInView} from "react-intersection-observer";
import {Col, Empty, Parameter, Row, Tag} from "../index";
import dayjs from "dayjs";
import styled from "styled-components";
import Element from "../composites/scroll/Element";
import SectionTitle from "../SectionTitle";

const Player = React.lazy(() => import('../Player'));

const TagsWrapper = styled.div`
    display: flex;
    margin-top: 13px;

    & span {
        margin-right: 10px;
    }
;
`;

const CallParametersWrapper = styled.div`
    margin-top: 25px;
    display: grid;
    grid-template-columns: ${({breakpoints}) => breakpoints.md.viewport ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'};
    grid-row-gap: ${({breakpoints}) => {
    if (breakpoints.md.viewport) return '18px';
    return '14px';
}};
`;

const CallWrapper = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: ${({theme}) => theme.colors.gray[3]};
    border-radius: 4px;
    padding: 20px 20px 14px;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
;
`;

const CallRecord = ({call}) => {
    return (
        <React.Suspense>
            <div>
                <Player url={`${import.meta.env.VITE_CALLS_HOST}/${call.filename}.mp3`}
                        minimal={true}
                        filename={call.filename}
                />
                <TagsWrapper>
                    <Tag value={call.type_display.title}/>
                    <Tag type={call.status_display.style}
                         value={call.status_display.title}
                    />
                </TagsWrapper>
            </div>
        </React.Suspense>
    )
};

const CallParameters = ({call}) => {
    const breakpoints = useBreakpoints();

    return (
        <div>
            <CallParametersWrapper breakpoints={breakpoints}>
                <Parameter
                    type={'column'}
                    name={'От'}
                    value={call.type === 'in' ? (
                        call.contact.short_name
                    ) : call.type === 'out' ? (
                        call.employee.short_name
                    ) : 'Неизвестно'}
                    valueSize={'s'}
                    valueWeight={'700'}
                >
                    <Parameter
                        type={'row'}
                        valueSize={'s'}
                        value={call.type === 'in' ? (
                            call.contact_number
                        ) : call.type === 'out' ? (
                            call.employee_number
                        ) : '—'}
                    />
                </Parameter>
                <Parameter
                    type={'column'}
                    name={'Кому'}
                    value={call.type === 'in' ? (
                        call.employee.short_name
                    ) : call.type === 'out' ? (
                        call.contact.short_name
                    ) : 'Неизвестно'}
                    valueSize={'s'}
                    valueWeight={'700'}
                >
                    <Parameter
                        type={'row'}
                        valueSize={'s'}
                        value={call.type === 'in' ? (
                            call.employee_number
                        ) : call.type === 'out' ? (
                            call.contact_number
                        ) : '—'}
                    />
                </Parameter>
                <Parameter
                    type={'column'}
                    name={'Когда'}
                    value={dayjs(call.created_at).format('D MMMM YYYY в HH:mm')}
                    valueSize={'s'}
                />
            </CallParametersWrapper>
        </div>
    )
};

const Call = ({call}) => {
    return (
        <CallWrapper>
            <CallRecord call={call}/>
            <CallParameters call={call}/>
        </CallWrapper>
    )
};

const Calls = ({title, contact}) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });
    const breakpoints = useBreakpoints();

    return (
        <Element name={'calls'}>
            <Row
                pt={breakpoints.md.viewport ? 56 : 21}
                pb={
                    data && data.length > 0
                        ? breakpoints.md.viewport
                            ? 5
                            : 7
                        : 0
                }
            >
                <Col xs={24} sm={24}>
                    <div ref={ref}>
                        <SectionTitle
                            title={title}
                        />
                        <Row>
                            <Col xs={24} sm={24}>
                                {data && data.length > 0 ? (
                                    data.map((call, i) => <Call key={i}
                                                                call={call}
                                    />)
                                ) : data && data.length === 0 ? (
                                    <Empty message={'Нет звонков.'}/>
                                ) : <div>Загрузка</div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Element>
    )
};

export {Calls};
