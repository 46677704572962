import {redirect} from "@tanstack/react-router";
import {UseUserReturn} from "../../hooks";
import i18n from "../../locale/i18n";

export const applyProtectedRoute = async ({user}: { user: UseUserReturn['user'] }) => {
    if (!i18n.hasLoadedNamespace('links')) {
        await i18n.loadNamespaces('links');
    }

    if (!user.loggedIn) {
        throw redirect({
            to: i18n.t('account.login.absolute', {ns: 'links'}),
            replace: true,
        })
    }
};

export const applyStaffProtectedRoute = async ({user}: { user: UseUserReturn['user'] }) => {
    if (!i18n.hasLoadedNamespace('links')) {
        await i18n.loadNamespaces('links');
    }

    await applyProtectedRoute({user});

    if (!('isStaff' in user && user.isStaff)) {
        throw redirect({
            to: i18n.t('home.absolute', {ns: 'links'}),
            replace: true,
        })
    }
};
