import {createRouter as createTanstackRouter} from "@tanstack/react-router";
import {routeTree} from "./routeTree.gen";

export const createRouter = () => {
    return createTanstackRouter({
        routeTree,
        defaultPreload: 'intent',
        defaultStaleTime: 5000,
        scrollRestoration: true,
        context: {
            user: undefined!,
        },
    });
}

declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>
    }
}
