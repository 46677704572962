import * as React from "react";
import {useAccount} from "../hooks";
import * as Sentry from "@sentry/react";
import {useCookies} from "react-cookie";
import {EventName, EventProperties, eventsAllowList} from "../analytics";
import {ApiObject, RudderAnalytics} from "@rudderstack/analytics-js";
import {CLSMetric, FCPMetric, FIDMetric, INPMetric, LCPMetric, TTFBMetric} from "web-vitals";
import preparePath from "../helpers/urls/path";
import {useLocation, useSearch} from "@tanstack/react-router";

type TTrackWebVitals = (metric: CLSMetric | FCPMetric | FIDMetric | INPMetric | LCPMetric | TTFBMetric) => void;

type TReportWebVitals = (onPerfEntry: TTrackWebVitals) => void;

type TProvider = {
    children: React.ReactNode
};

type UseAnalyticsContext = ReturnType<typeof useAnalyticsProvider>;

const analyticsContext = React.createContext<UseAnalyticsContext>({} as UseAnalyticsContext);

const AnalyticsProvider: React.FC<TProvider> = ({children}) => {
    const analytics = useAnalyticsProvider();

    return (
        <analyticsContext.Provider value={analytics}>
            {children}
        </analyticsContext.Provider>
    );
};

const useAnalytics = () => {
    return React.useContext(analyticsContext);
};

const useAnalyticsProvider = () => {
    const {user} = useAccount();
    const location = useLocation();
    const search = useSearch({strict: false});
    const deferredPathname = React.useDeferredValue(preparePath(location.pathname) + location.search);
    const [{yclid, sputnik_anonymous_id}, setCookie] = useCookies(['yclid', 'sputnik_anonymous_id']);

    React.useEffect(
        () => {
            if (search.yclid && search.yclid !== yclid) {
                let expires = new Date();
                expires.setTime(expires.getTime() + (90 * 24 * 60 * 60 * 1000));
                setCookie('yclid', search.yclid, {path: '/', expires});
            }
        },
        [search]
    );

    React.useEffect(
        () => {
            if (
                user.loggedIn &&
                'id' in user &&
                window.rudderanalytics &&
                'identify' in window.rudderanalytics
            ) (window.rudderanalytics as RudderAnalytics).identify(
                user.id,
                {},
                {
                    anonymousId: sputnik_anonymous_id,
                }
            );
        },
        [user.loggedIn]
    );

    React.useEffect(
        () => {
            const properties: ApiObject = {
                path: preparePath(location.pathname) + location.search,
                url: window.location.href,
                tab_url: window.location.href,
                search: window.location.search,
            };

            if (properties.path !== deferredPathname) properties.referrer = deferredPathname;

            if (window.rudderanalytics && 'page' in window.rudderanalytics) {
                (window.rudderanalytics as RudderAnalytics).page(
                    properties,
                    {
                        anonymousId: sputnik_anonymous_id,
                    },
                );
            }

            if (window.ym) {
                const ymProperties: { [k: string]: any } = {};

                if (properties.referrer) ymProperties.referer = cleanUrl(window.location.origin + properties.referrer);

                window.ym(
                    import.meta.env.VITE_YANDEX_METRIKA_ACCOUNT,
                    'hit',
                    cleanUrl(window.location.origin + properties.path),
                    ymProperties
                );
            }
        },
        [location]
    );

    React.useEffect(
        () => reportWebVitals(trackWebVitals),
        []
    );

    React.useEffect(
        () => track('Application Opened'),
        []
    );

    const track = <T extends EventName>(event: T, properties?: EventProperties[T]): void => {
        try {
            if (!event || !eventsAllowList.includes(event)) return;
            if (window.rudderanalytics && 'track' in window.rudderanalytics) {
                (window.rudderanalytics as RudderAnalytics).track(
                    event,
                    properties as ApiObject || {},
                    {
                        anonymousId: sputnik_anonymous_id,
                    }
                )
            }
            if (window.ym) {
                window.ym(
                    import.meta.env.VITE_YANDEX_METRIKA_ACCOUNT,
                    'reachGoal',
                    event.split(' ').join(''),
                    properties
                );
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const trackWebVitals: TTrackWebVitals = ({name, ...props}) => track(name, props);

    const reportWebVitals: TReportWebVitals = (onPerfEntry) => {
        if (onPerfEntry) {
            import('web-vitals').then(({onCLS, onFCP, onFID, onINP, onLCP, onTTFB}) => {
                onCLS(onPerfEntry);
                onFCP(onPerfEntry);
                onFID(onPerfEntry);
                onINP(onPerfEntry);
                onLCP(onPerfEntry);
                onTTFB(onPerfEntry);
            });
        }
    };

    const cleanUrl = (url: string) => {
        return url.replace(/\/+$/, '');
    };

    const getYmClientID = (callback: (id: any) => void) => {
        if (window.ym) {
            window.ym(
                import.meta.env.VITE_YANDEX_METRIKA_ACCOUNT,
                'getClientID',
                callback
            );
        }
    };

    return React.useMemo(() => ({track, getYmClientID}), []);
};

export {AnalyticsProvider, useAnalytics}
