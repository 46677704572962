import {createFileRoute} from '@tanstack/react-router';

type DevelopmentPropertiesSearch = {
    page?: number;
};

export const Route = createFileRoute(
    '/ru/novostroyki/$developmentSlug/$developmentOffer',
)({
    validateSearch: (
        search: Record<string, unknown>,
    ): DevelopmentPropertiesSearch => {
        return {
            page: search?.page ? Number(search.page) : undefined,
        };
    },
});
