import * as React from "react";
import {twMerge} from "tailwind-merge";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    ref?: React.Ref<HTMLInputElement>;
}

const InputField = ({className, ref, ...props}: InputFieldProps) => (
    <input
        ref={ref}
        className={twMerge(
            'block w-full h-full align-middle text-base text-cultured-700 font-semibold bg-transparent rounded-lg px-3.5 shadow-none outline-0 focus:outline-hidden focus:ring-0',
            className,
        )}
        {...props}
    />
);

export default InputField;
