import {createFileRoute} from '@tanstack/react-router';

type DevelopmentsSearch = {
    page?: number;
};

export const Route = createFileRoute('/ru/novostroyki/')({
    validateSearch: (search: Record<string, unknown>): DevelopmentsSearch => {
        return {
            page: search?.page ? Number(search.page) : undefined,
        };
    },
});
