import {createFileRoute} from '@tanstack/react-router';
import {applyStaffProtectedRoute} from '@routes/middlewares';

export const Route = createFileRoute(
    '/ru/account/whatsapp/accounts/$whatsappAccountId/',
)({
    beforeLoad: async ({context}) => {
        await applyStaffProtectedRoute({user: context.user});
    },
});
